/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgProductCosts = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={props.width || "100%"}
    height={props.height || "100%"}
    viewBox="0 0 16 16"
    aria-label="SvgProductCosts"
    {...props}
  >
    {props.children}
    <path
      d="M9 13.5H3.5a1 1 0 0 1-1-1v-8M2.5 4.5v-1a1 1 0 0 1 1-1h9a1 1 0 0 1 1 1v2.625M6 5.5h4M13.512 9.4V8M13.512 15v-1.4M11 13.5h3.5a1 1 0 0 0 0-2h-2a1 1 0 0 1 0-2H15"
      fill="none"
      stroke="#000"
      strokeMiterlimit="10"
    />
  </svg>
)
