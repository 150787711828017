/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgThumbsDownSolid = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 24 24"
    aria-label="SvgThumbsDownSolid"
    {...props}
  >
    {props.children}
    <path
      fill="#000"
      d="M16 2.6c0-.56 0-.84-.109-1.054a1 1 0 0 0-.437-.437C15.24 1 14.96 1 14.4 1H8.08c-.698 0-1.278 0-1.756.036-.498.038-.957.12-1.401.32a4 4 0 0 0-1.7 1.458c-.265.408-.415.85-.529 1.337-.108.466-.196 1.04-.303 1.73l-.535 3.481c-.141.915-.257 1.665-.297 2.278-.042.631-.015 1.22.2 1.786a4 4 0 0 0 1.76 2.051c.526.299 1.105.416 1.735.47C5.866 16 6.624 16 7.55 16h.85c.297 0 .459 0 .575.01l.014.002v.013c.01.116.011.279.011.575v2.934A3.466 3.466 0 0 0 12.466 23c.72 0 1.372-.424 1.665-1.082l3.36-7.562c.082-.183.125-.278.16-.344l.003-.006h.006c.075-.005.18-.006.38-.006h.799c.527 0 .982 0 1.356-.03.395-.033.789-.104 1.167-.297a3 3 0 0 0 1.311-1.311c.193-.378.264-.772.297-1.167.03-.374.03-.83.03-1.356V5.16c0-.527 0-.981-.03-1.356-.033-.395-.104-.789-.297-1.167a3 3 0 0 0-1.31-1.311c-.38-.193-.772-.264-1.168-.296C19.821 1 19.365 1 18.84 1a.84.84 0 0 0-.839.839V10a1 1 0 1 1-2 0z"
    />
  </svg>
)
