/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgMessageQuestionCircleSolid = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 24 24"
    aria-label="SvgMessageQuestionCircleSolid"
    {...props}
  >
    {props.children}
    <path
      fill="#000"
      fillRule="evenodd"
      d="M12.5 2a9.5 9.5 0 0 0-9.004 12.536c.056.168.09.267.111.34l.007.025-.003.008a8 8 0 0 1-.136.256l-1.652 3.058c-.102.19-.209.387-.282.559-.073.17-.192.484-.133.856a1.5 1.5 0 0 0 .7 1.048c.321.196.656.206.842.204a7 7 0 0 0 .624-.047l5.155-.533.158-.016h.004l.012.004c.04.014.092.035.183.07A9.5 9.5 0 0 0 22 11.5 9.5 9.5 0 0 0 12.5 2m-.512 6.672A1.249 1.249 0 0 1 13.87 9.75v.002c0 .218-.177.52-.679.854a4.3 4.3 0 0 1-.879.443l-.009.003h.002a1 1 0 0 0-.632 1.264c.173.519.75.804 1.266.632q.068-.023.136-.049a6.281 6.281 0 0 0 1.226-.63c.622-.414 1.57-1.237 1.57-2.517A3.248 3.248 0 0 0 9.556 8.67a1 1 0 0 0 1.886.664c.098-.278.291-.513.545-.662M12.65 14a1 1 0 1 0 0 2h.01a1 1 0 1 0 0-2z"
      clipRule="evenodd"
    />
  </svg>
)
