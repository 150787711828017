/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgStar01Solid = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 24 24"
    aria-label="SvgStar01Solid"
    {...props}
  >
    {props.children}
    <path
      fill="#000"
      d="M12.646 1.776a1.5 1.5 0 0 0-1.292 0c-.353.168-.547.46-.645.62a8 8 0 0 0-.307.583L8.246 7.346l-4.822.705c-.227.033-.46.067-.65.113-.182.044-.52.139-.789.423a1.5 1.5 0 0 0-.398 1.228c.051.387.27.663.391.805.127.148.295.312.46.472l3.488 3.397-.823 4.799a8 8 0 0 0-.095.652c-.014.187-.029.538.158.882a1.5 1.5 0 0 0 1.045.759c.384.071.714-.051.887-.123.18-.075.388-.184.591-.291L12 18.9l4.31 2.267c.204.107.412.216.592.29.174.073.503.195.887.124a1.5 1.5 0 0 0 1.045-.76c.187-.343.172-.694.158-.881a8 8 0 0 0-.095-.652l-.823-4.799 3.488-3.397c.165-.16.333-.324.46-.472.122-.142.34-.418.39-.805a1.5 1.5 0 0 0-.397-1.228c-.27-.284-.607-.38-.79-.423-.189-.046-.422-.08-.649-.113l-4.822-.705L13.6 2.98a8 8 0 0 0-.308-.582c-.098-.16-.292-.453-.645-.621"
    />
  </svg>
)
