/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgUndepositedFunds = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    viewBox="0 0 16 16"
    aria-label="SvgUndepositedFunds"
    {...props}
  >
    {props.children}
    <path d="M5.5 1C4.676 1 4 1.676 4 2.5V3H2.5C1.676 3 1 3.676 1 4.5v6c0 .824.676 1.5 1.5 1.5h10c.824 0 1.5-.676 1.5-1.5v-6c0-.824-.676-1.5-1.5-1.5H11v-.5c0-.824-.676-1.5-1.5-1.5ZM6 2h3c0 .547.453 1 1 1v4H8.906c.055-.156.094-.324.094-.5C9 5.676 8.324 5 7.5 5S6 5.676 6 6.5c0 .176.04.344.094.5H5V3c.547 0 1-.453 1-1m1.5 1c-.277 0-.5.223-.5.5s.223.5.5.5.5-.223.5-.5-.223-.5-.5-.5m-5 1H4v3H3v1h9V7h-1V4h1.5c.281 0 .5.219.5.5v6c0 .281-.219.5-.5.5h-10a.494.494 0 0 1-.5-.5v-6c0-.281.219-.5.5-.5m5 2c.281 0 .5.219.5.5s-.219.5-.5.5a.494.494 0 0 1-.5-.5c0-.281.219-.5.5-.5M4 13v1h7v-1Z" />
  </svg>
)
