/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgRecruiting = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    viewBox="0 0 16 16"
    aria-label="SvgRecruiting"
    {...props}
  >
    {props.children}
    <path d="M6.5 1C5.676 1 5 1.676 5 2.5V3H2.5A1.5 1.5 0 0 0 1 4.5v8A1.5 1.5 0 0 0 2.5 14H9v-.086L8.086 13H2.5a.5.5 0 0 1-.5-.5V10h7V9H2V4.5c0-.277.227-.5.5-.5h11c.273 0 .5.223.5.5V9h1V4.5A1.5 1.5 0 0 0 13.5 3H11v-.5c0-.824-.676-1.5-1.5-1.5Zm0 1h3c.281 0 .5.219.5.5V3H6v-.5c0-.281.219-.5.5-.5M7 7v1h2V7Zm5.5 2-1 1H10v1.5l-1 1 1 1V15h1.5l1 1 1-1H15v-1.5l1-1-1-1V10h-1.5Zm0 1.414.586.586H14v.914l.586.586-.586.586V14h-.914l-.586.586-.586-.586H11v-.914l-.586-.586.586-.586V11h.914Z" />
  </svg>
)
