/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgGetPaidBankIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 57 57"
    aria-label="SvgGetPaidBankIcon"
    {...props}
  >
    {props.children}
    <g clipPath="url(#get-paid-bank-icon_svg__a)">
      <g filter="url(#get-paid-bank-icon_svg__b)">
        <rect width={57} height={57} fill="url(#get-paid-bank-icon_svg__c)" rx={10} />
      </g>
      <g filter="url(#get-paid-bank-icon_svg__d)">
        <path
          fill="url(#get-paid-bank-icon_svg__e)"
          fillRule="evenodd"
          d="M39.369 24.136h2.033a1.375 1.375 0 0 0 1.375-1.375v-4.125a1.37 1.37 0 0 0-.94-1.303l-12.374-4.125a1.4 1.4 0 0 0-.391-.07h-.09a1.4 1.4 0 0 0-.39.07l-12.375 4.125c-.56.187-.94.712-.94 1.303v4.125a1.375 1.375 0 0 0 1.375 1.375h2.092v9.723a4.13 4.13 0 0 0-3.236 4.027v2.75a1.375 1.375 0 0 0 1.375 1.375h24.75a1.375 1.375 0 0 0 1.375-1.375v-2.75a4.13 4.13 0 0 0-3.64-4.095zm-19.736 9.625q-.33 0-.65.053.32-.057.655-.058h4.606v-9.62h9.625v9.62h5.019q.084 0 .166.01l-.171-.005z"
          clipRule="evenodd"
        />
      </g>
      <path
        fill="#fff"
        fillOpacity={0.8}
        fillRule="evenodd"
        d="M41.144 24.38H39.11v9.654a4.13 4.13 0 0 1 3.639 4.096v2.75a1.375 1.375 0 0 1-1.375 1.374h-24.75a1.375 1.375 0 0 1-1.375-1.374v-2.75a4.13 4.13 0 0 1 3.236-4.028V24.38h-2.093a1.375 1.375 0 0 1-1.375-1.375V18.88c0-.591.38-1.116.941-1.303l12.375-4.125c.282-.095.587-.095.87 0l12.375 4.125a1.37 1.37 0 0 1 .94 1.303v4.125a1.375 1.375 0 0 1-1.375 1.375m-22.42 9.677q.322-.052.65-.053h19.25q.087 0 .171.004A2 2 0 0 0 38.63 34h-5.018v-9.62h-9.625V34h-4.607q-.335.001-.654.058"
        clipRule="evenodd"
      />
      <path
        fill="#642979"
        fillOpacity={0.1}
        fillRule="evenodd"
        d="M28.491 13.349h1.01l12.11 4.017c.584.193.979.736.983 1.35l.038 5.114a.29.29 0 0 1-.292.294h-2.974v3.406l-.193-.075v6.176a4.29 4.29 0 0 1 3.982 4.28v3.106c0 .628-.51 1.138-1.138 1.138H37V36.04c0-.477-.233-.924-.624-1.197l-1.755-1.224h2.276v-7.045l-2.552-.986v-1.941h2.565V18.73a.58.58 0 0 0-.284-.5zm-6.387 11.168H18.69v1.707l3.414 1.28v7.254h2.276V24.517h-2.276"
        clipRule="evenodd"
      />
      <path
        fill="#fff"
        fillOpacity={0.7}
        d="M19.828 38.172a2.276 2.276 0 1 1 4.552 0v2.276c0 .629-.51 1.138-1.139 1.138h-2.276c-.628 0-1.137-.51-1.137-1.138z"
      />
      <path
        fill="#fff"
        d="m21.141 17.077 7.221-3.37-3.69 4.15a3.4 3.4 0 0 0-.862 2.268v2.685c0 .629-.51 1.138-1.138 1.138h-1.707c-.628 0-1.137-.51-1.137-1.138v-3.67c0-.885.512-1.69 1.313-2.063"
      />
    </g>
    <defs>
      <linearGradient
        id="get-paid-bank-icon_svg__c"
        x1={3.094}
        x2={55.331}
        y1={5.202}
        y2={45.197}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#B95DD9" />
        <stop offset={1} stopColor="#3C9ACF" />
      </linearGradient>
      <linearGradient
        id="get-paid-bank-icon_svg__e"
        x1={16.782}
        x2={42.947}
        y1={15.773}
        y2={35.012}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#B95DD9" />
        <stop offset={1} stopColor="#3C9ACF" />
      </linearGradient>
      <filter
        id="get-paid-bank-icon_svg__b"
        width={102.415}
        height={102.415}
        x={-22.708}
        y={-22.708}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset />
        <feGaussianBlur stdDeviation={11.354} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_1_3" />
        <feBlend in="SourceGraphic" in2="effect1_dropShadow_1_3" result="shape" />
      </filter>
      <filter
        id="get-paid-bank-icon_svg__d"
        width={79.411}
        height={80.554}
        x={-10.563}
        y={-12.703}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset />
        <feGaussianBlur stdDeviation={12.92} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_1_3" />
        <feBlend in="SourceGraphic" in2="effect1_dropShadow_1_3" result="shape" />
      </filter>
      <clipPath id="get-paid-bank-icon_svg__a">
        <path fill="#fff" d="M0 0h57v57H0z" />
      </clipPath>
    </defs>
  </svg>
)
