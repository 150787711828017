/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgSearchShareholder = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 31 31"
    aria-label="SvgSearchShareholder"
    {...props}
  >
    {props.children}
    <g filter="url(#search_shareholder_svg__a)">
      <path
        fill="url(#search_shareholder_svg__b)"
        fillRule="evenodd"
        d="M3.525 16.182v1.158a1.16 1.16 0 0 1 0 2.318v1.16a1.16 1.16 0 0 1 0 2.319v1.319a1 1 0 0 0 1 1h1.316v-.002a1.16 1.16 0 1 1 2.319 0v.002h1.16v-.002a1.16 1.16 0 1 1 2.318 0v.002h1.159v-.002a1.16 1.16 0 1 1 2.318 0v.002h1.16v-.002a1.16 1.16 0 1 1 2.318 0v.002h1.159v-.002a1.16 1.16 0 0 1 2.318 0v.002h1.16v-.002a1.16 1.16 0 1 1 2.319 0v.002h1.318a1 1 0 0 0 1-1v-1.32h-.001a1.16 1.16 0 0 1 0-2.318h.001v-1.16h-.001a1.159 1.159 0 1 1 0-2.318h.001v-1.158h-.001a1.16 1.16 0 0 1 0-2.319h.001v-1.16h-.001a1.16 1.16 0 0 1 0-2.318h.001V9.227h-.001a1.16 1.16 0 1 1 0-2.319h.001V6.75a1 1 0 0 0-1-1h-1.319a1.16 1.16 0 0 1-2.318 0h-1.16a1.16 1.16 0 0 1-2.318 0h-1.159a1.16 1.16 0 0 1-2.318 0h-1.16a1.16 1.16 0 0 1-2.318 0h-1.159a1.16 1.16 0 0 1-2.318 0H8.16a1.16 1.16 0 0 1-2.319 0H4.525a1 1 0 0 0-1 1v.158a1.16 1.16 0 0 1 0 2.319v1.158a1.16 1.16 0 0 1 0 2.318v1.16a1.16 1.16 0 0 1 0 2.319"
        clipRule="evenodd"
      />
    </g>
    <g filter="url(#search_shareholder_svg__c)">
      <path
        fill="#00A69C"
        fillRule="evenodd"
        d="M3.525 16.182v1.158a1.16 1.16 0 0 1 0 2.318v1.16a1.16 1.16 0 0 1 0 2.319v1.319a1 1 0 0 0 1 1h1.316v-.002a1.16 1.16 0 1 1 2.319 0v.002h1.16v-.002a1.16 1.16 0 1 1 2.318 0v.002h1.159v-.002a1.16 1.16 0 1 1 2.318 0v.002h1.16v-.002a1.16 1.16 0 1 1 2.318 0v.002h1.159v-.002a1.16 1.16 0 0 1 2.318 0v.002h1.16v-.002a1.16 1.16 0 1 1 2.319 0v.002h1.318a1 1 0 0 0 1-1v-1.32h-.001a1.16 1.16 0 0 1 0-2.318h.001v-1.16h-.001a1.159 1.159 0 1 1 0-2.318h.001v-1.158h-.001a1.16 1.16 0 0 1 0-2.319h.001v-1.16h-.001a1.16 1.16 0 0 1 0-2.318h.001V9.227h-.001a1.16 1.16 0 1 1 0-2.319h.001V6.75a1 1 0 0 0-1-1h-1.319a1.16 1.16 0 0 1-2.318 0h-1.16a1.16 1.16 0 0 1-2.318 0h-1.159a1.16 1.16 0 0 1-2.318 0h-1.16a1.16 1.16 0 0 1-2.318 0h-1.159a1.16 1.16 0 0 1-2.318 0H8.16a1.16 1.16 0 0 1-2.319 0H4.525a1 1 0 0 0-1 1v.158a1.16 1.16 0 0 1 0 2.319v1.158a1.16 1.16 0 0 1 0 2.318v1.16a1.16 1.16 0 0 1 0 2.319"
        clipRule="evenodd"
      />
    </g>
    <g filter="url(#search_shareholder_svg__d)">
      <path fill="#fff" fillOpacity={0.01} d="M7.525 9.75h16v12h-16z" />
    </g>
    <path
      stroke="#53B398"
      strokeOpacity={0.7}
      strokeWidth={0.5}
      d="M7.584 9.574h15.882v12.353H7.584z"
    />
    <g filter="url(#search_shareholder_svg__e)">
      <path
        fill="url(#search_shareholder_svg__f)"
        fillOpacity={0.4}
        d="M21.105 13.1H9.947c-.425 0-.57-.421-.57-.846v-.218c0-.379.19-.7.57-.7h11.158c.424 0 .569.422.569.846v.219c0 .379-.19.7-.57.7"
      />
      <path
        fill="#83E1E3"
        d="M21.105 13.1H9.947c-.425 0-.57-.421-.57-.846v-.218c0-.379.19-.7.57-.7h11.158c.424 0 .569.422.569.846v.219c0 .379-.19.7-.57.7"
      />
    </g>
    <g filter="url(#search_shareholder_svg__g)">
      <path
        fill="url(#search_shareholder_svg__h)"
        fillOpacity={0.4}
        d="M17.917 16.632h-4.782c-.229 0-.244-.288-.244-.517v-.855c0-.186.057-.393.244-.393h4.782c.228 0 .243.288.243.517v.855c0 .186-.057.393-.243.393"
      />
      <path
        fill="#83E1E3"
        d="M17.917 16.632h-4.782c-.229 0-.244-.288-.244-.517v-.855c0-.186.057-.393.244-.393h4.782c.228 0 .243.288.243.517v.855c0 .186-.057.393-.243.393"
      />
    </g>
    <g filter="url(#search_shareholder_svg__i)">
      <path
        fill="url(#search_shareholder_svg__j)"
        fillOpacity={0.4}
        d="M19.51 20.163h-7.97c-.326 0-.407-.352-.407-.678v-.543c0-.282.124-.544.407-.544h7.97c.326 0 .406.353.406.679v.542c0 .283-.124.544-.406.544"
      />
      <path
        fill="#83E1E3"
        d="M19.51 20.163h-7.97c-.326 0-.407-.352-.407-.678v-.543c0-.282.124-.544.407-.544h7.97c.326 0 .406.353.406.679v.542c0 .283-.124.544-.406.544"
      />
    </g>
    <defs>
      <filter
        id="search_shareholder_svg__a"
        width={28.342}
        height={23.707}
        x={1.525}
        y={3.75}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation={1} />
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_18532_229003" />
        <feBlend in="SourceGraphic" in2="effect1_backgroundBlur_18532_229003" result="shape" />
      </filter>
      <filter
        id="search_shareholder_svg__c"
        width={26.342}
        height={21.707}
        x={2.525}
        y={4.75}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation={0.5} />
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_18532_229003" />
        <feBlend in="SourceGraphic" in2="effect1_backgroundBlur_18532_229003" result="shape" />
      </filter>
      <filter
        id="search_shareholder_svg__d"
        width={17}
        height={13}
        x={7.525}
        y={9.75}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dx={1} dy={1} />
        <feGaussianBlur stdDeviation={0.5} />
        <feComposite in2="hardAlpha" k2={-1} k3={1} operator="arithmetic" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend in2="shape" result="effect1_innerShadow_18532_229003" />
      </filter>
      <filter
        id="search_shareholder_svg__e"
        width={16.296}
        height={5.766}
        x={7.378}
        y={9.336}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation={1} />
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_18532_229003" />
        <feBlend in="SourceGraphic" in2="effect1_backgroundBlur_18532_229003" result="shape" />
      </filter>
      <filter
        id="search_shareholder_svg__g"
        width={9.27}
        height={5.766}
        x={10.891}
        y={12.867}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation={1} />
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_18532_229003" />
        <feBlend in="SourceGraphic" in2="effect1_backgroundBlur_18532_229003" result="shape" />
      </filter>
      <filter
        id="search_shareholder_svg__i"
        width={12.783}
        height={5.766}
        x={9.133}
        y={16.398}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation={1} />
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_18532_229003" />
        <feBlend in="SourceGraphic" in2="effect1_backgroundBlur_18532_229003" result="shape" />
      </filter>
      <linearGradient
        id="search_shareholder_svg__b"
        x1={23.556}
        x2={18.094}
        y1={21.027}
        y2={4.55}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#10A37A" />
        <stop offset={0.865} stopColor="#198667" />
      </linearGradient>
      <linearGradient
        id="search_shareholder_svg__f"
        x1={15.526}
        x2={15.526}
        y1={11.336}
        y2={13.101}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#10FBF5" />
        <stop offset={1} stopColor="#16CED8" />
      </linearGradient>
      <linearGradient
        id="search_shareholder_svg__h"
        x1={15.526}
        x2={15.526}
        y1={14.867}
        y2={16.632}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#10FBF5" />
        <stop offset={1} stopColor="#16CED8" />
      </linearGradient>
      <linearGradient
        id="search_shareholder_svg__j"
        x1={15.525}
        x2={15.525}
        y1={18.398}
        y2={20.163}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#10FBF5" />
        <stop offset={1} stopColor="#16CED8" />
      </linearGradient>
    </defs>
  </svg>
)
