/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgPto = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={props.width || "100%"}
    height={props.height || "100%"}
    viewBox="0 0 16 16"
    aria-label="SvgPto"
    {...props}
  >
    {props.children}
    <path d="M13.899 3.801a.5.5 0 0 0 .081-.438C13.596 2.016 12.108 1 10.521 1 9.246 1 8.124 1.532 7.5 2.333 6.876 1.532 5.754 1 4.479 1 2.893 1 1.405 2.016 1.02 3.362A.5.5 0 0 0 1.5 4h2.317C2.716 4.78 2 5.94 2 7.25c0 1.059.616 2.063 1.143 2.601A.502.502 0 0 0 4 9.5v-.25c0-1.161.791-2.208 1.956-2.79-.166 1.894-.902 4.26-3.134 6.135a6 6 0 0 1-1.042-.51l-.56.829C1.286 12.959 2.85 14 4.86 14c1.01 0 1.903-.264 2.766-.519.839-.247 1.632-.481 2.514-.481 1.715 0 3.065.904 3.078.913l.565-.825C13.718 13.043 12.174 12 10.14 12c-.859 0-1.629.188-2.369.399 1.051-2.013 1.204-4.18 1.224-5.966C10.188 7.01 11 8.072 11 9.25v.25a.5.5 0 0 0 .856.351C12.384 9.313 13 8.309 13 7.25c0-1.31-.716-2.47-1.817-3.25H13.5a.5.5 0 0 0 .399-.199M4.479 2c.986 0 1.871.409 2.288 1H2.323c.444-.554 1.227-1 2.156-1M3.181 8.105A2.3 2.3 0 0 1 3 7.25C3 5.458 4.794 4 7 4v1.063c-1.864.322-3.325 1.515-3.819 3.042M6.41 12.78c-.506.126-1.013.22-1.55.22-.307 0-.597-.036-.876-.085 2.261-2.166 2.903-4.802 3.001-6.819a5 5 0 0 1 .515-.09c.171.018.335.051.498.087-.006 2.071-.095 4.549-1.588 6.687M12 7.25c0 .286-.069.579-.181.855C11.325 6.578 9.864 5.385 8 5.063V4c2.206 0 4 1.458 4 3.25M8.234 3c.417-.591 1.301-1 2.288-1 .93 0 1.713.446 2.156 1z" />
  </svg>
)
