/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgInsurance = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={props.width || "100%"}
    height={props.height || "100%"}
    viewBox="0 0 16 16"
    aria-label="SvgInsurance"
    {...props}
  >
    {props.children}
    <path
      d="M2 11.5h-.5a1 1 0 0 1-1-1v-6a1 1 0 0 1 1-1H2M13 3.5h.5a1 1 0 0 1 1 1v6a1 1 0 0 1-1 1H13M3 5.5h9M3 7.5h7M3 9.5h9"
      fill="none"
      stroke="#000"
      strokeMiterlimit="10"
    />
  </svg>
)
