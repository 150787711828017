/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgShieldZapSolid = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 24 24"
    aria-label="SvgShieldZapSolid"
    {...props}
  >
    {props.children}
    <path
      fill="#000"
      fillRule="evenodd"
      d="M11.714 1.088a2 2 0 0 1 .572 0c.219.032.422.109.584.17l.043.016 5.493 2.06c.617.23 1.16.433 1.578.801a3 3 0 0 1 .82 1.184c.198.52.197 1.1.196 1.759V12c0 2.827-1.537 5.183-3.199 6.914-1.673 1.743-3.587 2.974-4.599 3.564l-.04.024c-.186.109-.425.25-.742.318a2.1 2.1 0 0 1-.84 0 2.4 2.4 0 0 1-.74-.317l-.042-.024c-1.012-.591-2.926-1.822-4.599-3.565C4.538 17.184 3 14.826 3 12V7.078c0-.66-.002-1.239.196-1.76a3 3 0 0 1 .82-1.183c.419-.368.961-.57 1.579-.801l5.492-2.06.044-.016c.161-.061.365-.138.583-.17m1.993 7.12a1 1 0 0 0-1.414-1.415l-3 3a1 1 0 0 0 .26 1.601l2.76 1.38-2.02 2.019a1 1 0 0 0 1.414 1.414l3-3a1 1 0 0 0-.26-1.601l-2.759-1.38z"
      clipRule="evenodd"
    />
  </svg>
)
