/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgComputersEquipment = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={props.width || "100%"}
    height={props.height || "100%"}
    viewBox="0 0 16 16"
    aria-label="SvgComputersEquipment"
    {...props}
  >
    {props.children}
    <path
      d="M13.5 11.5h-12a1 1 0 0 1-1-1v-7a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1zM10 13.5h-.994c-.828 0-1.5-.674-1.5-1.502 0-.281-.002-.49-.006-.498a9 9 0 0 0-.006.498c0 .828-.672 1.502-1.5 1.502H5M5 13.5h5M.5 10.5h14"
      fill="none"
      stroke="#000"
      strokeMiterlimit="10"
    />
  </svg>
)
