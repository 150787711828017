/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgOwnersCapital = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={props.width || "100%"}
    height={props.height || "100%"}
    viewBox="0 0 16 16"
    aria-label="SvgOwnersCapital"
    {...props}
  >
    {props.children}
    <path
      d="M4.5 2.5h7a1 1 0 0 1 1 1v9a1 1 0 0 1-1 1h-7a1 1 0 0 1-1-1v-9a1 1 0 0 1 1-1zM11 5.5H5M11 8.5H7M6 8.5H5M11 10.5H7M6 10.5H5"
      fill="none"
      stroke="#000"
      strokeMiterlimit="10"
    />
  </svg>
)
