/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgXero = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 45 14"
    aria-label="SvgXero"
    {...props}
  >
    {props.children}
    <g clipPath="url(#xero_svg__a)">
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M25.169 6.95c.293-.366.404-.843.31-1.347-.382-1.825-1.365-3.287-2.841-4.228A6.3 6.3 0 0 0 19.215.38c-1.352 0-2.64.411-3.722 1.187a6.54 6.54 0 0 0-2.511 6.872c.65 2.57 2.839 4.512 5.446 4.835a6.03 6.03 0 0 0 2.302-.158 6.3 6.3 0 0 0 1.923-.825c.564-.369 1.084-.866 1.63-1.56l.036-.037c.182-.23.267-.524.232-.809a.9.9 0 0 0-.34-.618 1 1 0 0 0-.598-.216c-.207 0-.498.075-.766.427l-.02.028c-.09.12-.181.243-.287.365-.361.409-.776.747-1.232 1.002a4.46 4.46 0 0 1-2.099.538c-2.317-.025-3.736-1.582-4.29-3.028a5 5 0 0 1-.19-.718q-.001-.034-.004-.069l8.973-.002c.624-.014 1.146-.243 1.47-.646zM14.795 5.72a4.53 4.53 0 0 1 4.387-3.439 4.53 4.53 0 0 1 4.391 3.438zm25.08 1.044a1.64 1.64 0 0 1-1.631 1.645 1.643 1.643 0 0 1-1.634-1.645 1.64 1.64 0 0 1 1.634-1.644c.9 0 1.63.738 1.63 1.644m-8.52-4.438c-.042 0-.206.001-.36.02-1.845.23-2.03 1.129-2.03 3.28v6.637a.93.93 0 0 1-.924.932.93.93 0 0 1-.925-.932L27.115 1.47a.922.922 0 0 1 1.803-.286A3.65 3.65 0 0 1 31.1.47l.262.003c.513 0 .931.42.931.937 0 .516-.394.893-.938.913zM12.73 11.68a.935.935 0 0 1-.66 1.596.93.93 0 0 1-.67-.294l-4.783-4.82-4.803 4.843a.94.94 0 0 1-.654.27.93.93 0 0 1-.927-.936c0-.255.102-.494.287-.671L5.306 6.83.514 1.998a.94.94 0 0 1-.28-.668c0-.515.416-.935.927-.935.247 0 .478.096.654.27l4.808 4.832 4.79-4.823a.92.92 0 0 1 .658-.28.934.934 0 0 1 .66 1.592L7.94 6.834l4.79 4.847M38.244.31c-3.529 0-6.4 2.896-6.4 6.455s2.871 6.454 6.4 6.454 6.396-2.895 6.396-6.454c0-3.56-2.87-6.455-6.396-6.455m0 10.979c-2.474 0-4.486-2.03-4.486-4.523 0-2.494 2.012-4.529 4.486-4.529s4.482 2.032 4.482 4.529-2.01 4.523-4.482 4.523"
        clipRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="xero_svg__a">
        <path fill="#fff" d="M.234.309H44.64V13.32H.234z" />
      </clipPath>
    </defs>
  </svg>
)
