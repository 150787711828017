/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgChevronDownSolid = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 24 24"
    aria-label="SvgChevronDownSolid"
    {...props}
  >
    {props.children}
    <path
      fill="#32497f"
      fillRule="evenodd"
      d="m17.447 10.484-.014.013-4.165 3.908c-.12.113-.232.218-.336.298a1.3 1.3 0 0 1-.478.24c-.29.076-.617.076-.908 0a1.3 1.3 0 0 1-.478-.24 5 5 0 0 1-.336-.298l-4.18-3.92a6 6 0 0 1-.37-.369c-.092-.105-.196-.25-.18-.42.02-.216.191-.415.467-.546.216-.102.467-.128.658-.14.191-.01.43-.01.695-.01h8.356c.265 0 .504 0 .695.01.191.012.442.038.658.14.276.13.447.33.467.547.016.169-.088.314-.18.42s-.223.229-.37.367"
      clipRule="evenodd"
    />
  </svg>
)
