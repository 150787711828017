/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgLongTermLiabilities = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={props.width || "100%"}
    height={props.height || "100%"}
    viewBox="0 0 16 16"
    aria-label="SvgLongTermLiabilities"
    {...props}
  >
    {props.children}
    <path
      d="M6 5.5H2.5a1 1 0 0 0-1 1v5a1 1 0 0 0 1 1h11a1 1 0 0 0 1-1V9"
      fill="none"
      stroke="#000"
      strokeMiterlimit="10"
    />
    <circle
      cx={8}
      cy={9}
      r={1.5}
      fill="none"
      stroke="#000"
      strokeLinecap="square"
      strokeMiterlimit="10"
    />
    <path
      d="M2 11.5a.5.5 0 0 1 .5.5M14 11.5a.5.5 0 0 0-.5.5M2 6.5a.5.5 0 0 0 .5-.5"
      fill="none"
      stroke="#000"
      strokeLinecap="square"
      strokeMiterlimit="10"
    />
    <circle cx={4} cy={9} r={1} />
    <circle cx={12} cy={9} r={1} />
    <circle
      cx={11.5}
      cy={3.5}
      r={3}
      fill="none"
      stroke="#000"
      strokeLinecap="square"
      strokeMiterlimit="10"
    />
    <path d="M7.76 6H5V5h2.26c.12.36.29.69.5 1M15 6.32V10h-1V7.24c.38-.25.72-.56 1-.92" />
  </svg>
)
