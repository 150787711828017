/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgExcel = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 36 35"
    aria-label="SvgExcel"
    {...props}
  >
    {props.children}
    <g clipPath="url(#excel_svg__a)">
      <path
        fill="#fff"
        d="M20.952 4.602V.06L.379 3.473v27.311L20.952 34.2v-4.56h14.41V4.602zM11.775 23.2l-1.7-4.07c-.132-.307-.106-.348-.133-.498-.012.107-.017.194-.088.388l-1.71 3.964-2.397-.143 2.772-5.628-2.495-5.645 2.405-.143 1.425 3.703c.102.218.149.453.173.61-.011-.183.027-.327.132-.61l1.688-3.906 2.593-.154q-1.575 3-3.029 6.062l3.16 6.236zM25.8 27.332h-4.848v-3.114H25.8zm0-4.314h-4.848v-3.114H25.8zm0-4.332h-4.848v-3.113H25.8zm0-4.324h-4.848v-3.114H25.8zm0-4.239h-4.848V7.01H25.8zm6.99 17.209h-5.767v-3.114h5.767zm0-4.286h-5.767v-3.114h5.767zm0-4.36h-5.767v-3.113h5.767zm0-4.31h-5.767v-3.114h5.767zm0-4.253h-5.767V7.01h5.767z"
      />
    </g>
    <defs>
      <clipPath id="excel_svg__a">
        <path fill="#fff" d="M.383.06h34.983v34.139H.383z" />
      </clipPath>
    </defs>
  </svg>
)
