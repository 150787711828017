/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgOtherAssets = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={props.width || "100%"}
    height={props.height || "100%"}
    viewBox="0 0 16 16"
    aria-label="SvgOtherAssets"
    {...props}
  >
    {props.children}
    <circle cx={8} cy={8} r={5.5} fill="none" stroke="#000" strokeMiterlimit="10" />
    <path d="M8 2.5V8h5.5A5.5 5.5 0 0 0 8 2.5" />
  </svg>
)
