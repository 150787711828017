/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgBonuses = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={props.width || "100%"}
    height={props.height || "100%"}
    viewBox="0 0 16 16"
    aria-label="SvgBonuses"
    {...props}
  >
    {props.children}
    <path
      d="M1.5 12.708V5.292c0-.437.448-.792 1-.792h10c.552 0 1 .354 1 .792v7.417c0 .437-.448.792-1 .792h-10c-.552-.001-1-.355-1-.793zM1.5 7.5H14M5.833 4.5S4.5 4.5 4.5 3.5a1 1 0 0 1 1-1c1 0 1.667 1.333 1.667 1.333M9.167 4.5s1.333 0 1.333-1a1 1 0 0 0-1-1c-1 0-1.667 1.333-1.667 1.333M7.5 4.625V8"
      fill="none"
      stroke="#000"
      strokeMiterlimit="10"
    />
    <path
      d="M8.5 2.5c0 .552-1 2.125-1 2.125s-1-1.573-1-2.125a1 1 0 0 1 2 0zM7.5 9v4.5"
      fill="none"
      stroke="#000"
      strokeMiterlimit="10"
    />
  </svg>
)
