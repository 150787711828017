/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgFacility = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={props.width || "100%"}
    height={props.height || "100%"}
    viewBox="0 0 16 16"
    aria-label="SvgFacility"
    {...props}
  >
    {props.children}
    <path
      d="M10.5 10.5c-2.5 0-2.25-.125-4-1.5-.564-.443-1-.5-1.5-.5-.451 0-1.03.135-1.03.135L1.5 9.5l1 3 2.5-1 3.375 1.665a2 2 0 0 0 1.966.141L14.5 10.5"
      fill="none"
      stroke="#000"
      strokeLinecap="square"
      strokeLinejoin="round"
      strokeMiterlimit="10"
    />
    <path
      d="M5.5 2.75v-.833c0-.23.187-.417.417-.417h4.167c.23 0 .417.187.417.417v4.166c0 .23-.187.417-.417.417H5.917a.417.417 0 0 1-.417-.417V2.75M7 3.5h2"
      fill="none"
      stroke="#000"
      strokeMiterlimit="10"
    />
  </svg>
)
