/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgArrowBlockLeftSolid = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 24 24"
    aria-label="SvgArrowBlockLeftSolid"
    {...props}
  >
    {props.children}
    <path
      fill="#000"
      fillRule="evenodd"
      d="M10.383 4.076A1 1 0 0 1 11 5v3h9.223c.12 0 .262 0 .387.01.145.012.353.042.571.153a1.5 1.5 0 0 1 .656.656c.11.218.141.426.153.571.01.125.01.268.01.387v4.446c0 .12 0 .261-.01.387a1.5 1.5 0 0 1-.153.571 1.5 1.5 0 0 1-.656.655 1.5 1.5 0 0 1-.571.154c-.125.01-.268.01-.387.01H11v3a1 1 0 0 1-1.707.707l-7-7a1 1 0 0 1 0-1.414l7-7a1 1 0 0 1 1.09-.217"
      clipRule="evenodd"
    />
  </svg>
)
