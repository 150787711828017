/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgUserDoc = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 24 24"
    aria-label="SvgUserDoc"
    {...props}
  >
    {props.children}
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M10.473 15.5H7.5c-1.396 0-2.093 0-2.661.172a4 4 0 0 0-2.667 2.667C2 18.907 2 19.604 2 21M14.5 7.5a4.5 4.5 0 1 1-9 0 4.5 4.5 0 0 1 9 0M16.656 13.482v2.928c0 .247 0 .37.048.464a.44.44 0 0 0 .192.193c.095.047.218.047.465.047h2.936m-2.852-3.642h-2.079c-.74 0-1.11 0-1.392.144-.249.127-.451.329-.578.578-.144.282-.144.652-.144 1.392v4.58c0 .74 0 1.11.144 1.392.127.25.329.451.578.578.282.144.652.144 1.392.144h2.819c.74 0 1.11 0 1.392-.144.248-.127.45-.329.577-.578.144-.282.144-.652.144-1.392v-3.832z"
    />
  </svg>
)
