/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgAnalysisDimensionWaterfall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 22 22"
    aria-label="SvgAnalysisDimensionWaterfall"
    {...props}
  >
    {props.children}
    <path
      fill="url(#analysis-dimension-waterfall_svg__a)"
      fillOpacity={0.4}
      d="M8.741 12.937h6.376a1 1 0 0 0 1-1V9.653a1 1 0 0 0-1-1H8.741a1 1 0 0 0-1 1v2.284a1 1 0 0 0 1 1"
    />
    <path
      fill="url(#analysis-dimension-waterfall_svg__b)"
      fillOpacity={0.4}
      d="M8.741 12.937h6.376a1 1 0 0 0 1-1V9.653a1 1 0 0 0-1-1H8.741a1 1 0 0 0-1 1v2.284a1 1 0 0 0 1 1"
    />
    <path
      fill="url(#analysis-dimension-waterfall_svg__c)"
      fillOpacity={0.4}
      d="M2.997 17.676H6.76a1 1 0 0 0 1-1v-2.284a1 1 0 0 0-1-1H2.997a1 1 0 0 0-1 1v2.284a1 1 0 0 0 1 1"
    />
    <path
      fill="url(#analysis-dimension-waterfall_svg__d)"
      d="M19.946 4h-2.764a1 1 0 0 0-1 1v2.284a1 1 0 0 0 1 1h2.764a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1"
    />
    <path
      fill="url(#analysis-dimension-waterfall_svg__e)"
      fillOpacity={0.4}
      d="M19.946 4h-2.764a1 1 0 0 0-1 1v2.284a1 1 0 0 0 1 1h2.764a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1"
    />
    <defs>
      <linearGradient
        id="analysis-dimension-waterfall_svg__a"
        x1={11.929}
        x2={11.929}
        y1={12.937}
        y2={8.653}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#10FBF5" />
        <stop offset={1} stopColor="#16CED8" />
      </linearGradient>
      <linearGradient
        id="analysis-dimension-waterfall_svg__b"
        x1={11.929}
        x2={11.929}
        y1={12.937}
        y2={8.653}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#10FBF5" />
        <stop offset={1} stopColor="#16CED8" />
      </linearGradient>
      <linearGradient
        id="analysis-dimension-waterfall_svg__c"
        x1={4.878}
        x2={4.878}
        y1={17.676}
        y2={13.392}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#10FBF5" />
        <stop offset={1} stopColor="#16CED8" />
      </linearGradient>
      <linearGradient
        id="analysis-dimension-waterfall_svg__d"
        x1={19.471}
        x2={18.264}
        y1={6.972}
        y2={3.887}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#9AF9FF" stopOpacity={0.69} />
        <stop offset={0.865} stopColor="#D4FEFD" />
      </linearGradient>
      <linearGradient
        id="analysis-dimension-waterfall_svg__e"
        x1={18.564}
        x2={18.564}
        y1={4}
        y2={8.284}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#10FBF5" />
        <stop offset={1} stopColor="#16CED8" />
      </linearGradient>
    </defs>
  </svg>
)
