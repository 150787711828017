/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgKybcSoleProp = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 25 21"
    aria-label="SvgKybcSoleProp"
    {...props}
  >
    {props.children}
    <g clipPath="url(#kybc-sole-prop_svg__a)">
      <path
        fill="url(#kybc-sole-prop_svg__b)"
        d="M23.6 19.086c0 .97-.756 1.755-1.694 1.755H2.718c-.934 0-1.69-.786-1.69-1.755V5.353c0-.97.756-1.756 1.69-1.756h19.188c.933 0 1.694.786 1.694 1.756z"
      />
      <g filter="url(#kybc-sole-prop_svg__c)">
        <path
          fill="#fff"
          d="M23.166 3.59H1.46c-.613 0-1.11.517-1.11 1.153v6.387c0 .636.497 1.154 1.11 1.154l8.513 2.347h4.684l8.513-2.347c.613 0 1.11-.518 1.11-1.154V4.743c-.004-.636-.502-1.154-1.114-1.154"
        />
      </g>
      <g filter="url(#kybc-sole-prop_svg__d)">
        <path fill="#fff" d="M14.37 13.047h-3.948v3.044h3.948z" />
      </g>
      <path
        fill="#fff"
        d="M12.313.438C9.805.438 7.77 1.975 7.77 3.875s2.034 3.437 4.542 3.437 4.54-1.537 4.54-3.437S14.82.438 12.314.438m0 5.929c-1.824 0-3.297-1.12-3.297-2.497 0-1.378 1.478-1.557 3.297-1.557 1.818 0 3.297.18 3.297 1.557s-1.474 2.497-3.297 2.497"
      />
    </g>
    <defs>
      <filter
        id="kybc-sole-prop_svg__c"
        width={28.983}
        height={16.094}
        x={-2.176}
        y={1.063}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset />
        <feGaussianBlur stdDeviation={1.263} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_4313_6376" />
        <feBlend in="SourceGraphic" in2="effect1_dropShadow_4313_6376" result="shape" />
      </filter>
      <filter
        id="kybc-sole-prop_svg__d"
        width={7.737}
        height={6.833}
        x={8.527}
        y={11.153}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset />
        <feGaussianBlur stdDeviation={0.947} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_4313_6376" />
        <feBlend in="SourceGraphic" in2="effect1_dropShadow_4313_6376" result="shape" />
      </filter>
      <linearGradient
        id="kybc-sole-prop_svg__b"
        x1={12.314}
        x2={12.314}
        y1={3.597}
        y2={20.841}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.307} stopColor="#EEE" />
        <stop offset={1} stopColor="#fff" />
      </linearGradient>
      <clipPath id="kybc-sole-prop_svg__a">
        <path fill="#fff" d="M.35.438h23.93V20.84H.35z" />
      </clipPath>
    </defs>
  </svg>
)
