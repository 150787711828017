/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgDigitsShield = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 40 53"
    aria-label="SvgDigitsShield"
    width={props.width || "100%"}
    height={props.height || "100%"}
    {...props}
  >
    {props.children}
    <g clipPath="url(#digits-shield_svg__a)">
      <path d="M20.942 27.335h-3.715V23.44a.75.75 0 0 1 .752-.743.75.75 0 0 1 .752.743v2.412h2.21c1.4 0 2.543-1.121 2.543-2.509s-1.136-2.508-2.542-2.508h-10.92a.75.75 0 0 1-.751-.743.75.75 0 0 1 .752-.742h10.911c2.226 0 4.046 1.789 4.046 3.993 0 2.205-1.812 3.993-4.038 3.993" />
      <path d="M14.369 24.025h-2.647a.75.75 0 0 1-.752-.742.75.75 0 0 1 .752-.742h2.647a.75.75 0 0 1 .752.742.75.75 0 0 1-.752.742M14.505 27.328H9.752A.75.75 0 0 1 9 26.585a.75.75 0 0 1 .752-.742h4.753a.75.75 0 0 1 .752.742.75.75 0 0 1-.752.743" />
      <path d="M11.775 30.63a.743.743 0 0 1-.752-.734c0-.409.33-.743.745-.75l9.415-.052c3.21 0 5.82-2.575 5.82-5.745 0-.72-.135-1.417-.39-2.085a.734.734 0 0 1 .428-.958.75.75 0 0 1 .97.424q.496 1.259.496 2.62c0 3.985-3.286 7.228-7.324 7.228zM25.168 18.933a.78.78 0 0 1-.459-.156 5.8 5.8 0 0 0-3.526-1.172h-9.325a.75.75 0 0 1-.753-.742.75.75 0 0 1 .752-.743h9.326c1.616 0 3.15.512 4.436 1.477.331.253.392.713.143 1.04a.74.74 0 0 1-.594.296M20.934 33.851H16.31a.75.75 0 0 1-.752-.742.75.75 0 0 1 .752-.742h4.625c1.692 0 3.347-.46 4.79-1.321a.75.75 0 0 1 1.03.245.73.73 0 0 1-.247 1.017 10.73 10.73 0 0 1-5.573 1.543" />
      <path d="M28.515 30.4a.76.76 0 0 1-.49-.178.735.735 0 0 1-.082-1.046 9.01 9.01 0 0 0 .587-11.052 9.2 9.2 0 0 0-4.16-3.288.747.747 0 0 1-.42-.964.76.76 0 0 1 .977-.416c1.963.78 3.64 2.1 4.843 3.822a10.4 10.4 0 0 1 1.888 5.99 10.5 10.5 0 0 1-2.564 6.865.77.77 0 0 1-.58.267M21.957 14.243c-.022 0-.053 0-.075-.008a10 10 0 0 0-.94-.044h-4.813a.75.75 0 0 1-.752-.743.75.75 0 0 1 .752-.742h4.813c.368 0 .737.015 1.098.052a.744.744 0 0 1 .67.817.765.765 0 0 1-.753.668" />
      <path d="M19.973 53.001c-.444 0-.88-.118-1.279-.363a50.5 50.5 0 0 1-7.475-5.641C7.444 43.538 4.812 39.939 3.39 36.31-.167 27.188-.046 14.793.021 8.136v-.334a2.81 2.81 0 0 1 2.174-2.694C9.647 3.334 15.167 1.404 17.799.41a6.22 6.22 0 0 1 4.437.007c2.61.995 8.107 2.924 15.552 4.69 1.27.305 2.166 1.41 2.173 2.695v.334c.068 6.657.188 19.052-3.376 28.174-1.414 3.629-4.061 7.228-7.851 10.694a50.8 50.8 0 0 1-7.506 5.641 2.35 2.35 0 0 1-1.256.356m.045-51.515c-.572 0-1.136.104-1.677.304-2.663 1.002-8.257 2.961-15.793 4.75a1.32 1.32 0 0 0-1.023 1.27v.333c-.03 3.147-.075 7.897.324 12.914.466 5.953 1.459 10.91 2.948 14.718.797 2.034 4.196 9.166 14.694 15.601.294.186.67.186.963 0 10.543-6.435 13.95-13.567 14.74-15.6 1.488-3.816 2.481-8.766 2.955-14.719.398-5.017.353-9.767.323-12.914V7.81A1.33 1.33 0 0 0 37.45 6.54c-7.535-1.789-13.1-3.74-15.747-4.743a4.7 4.7 0 0 0-1.684-.311" />
    </g>
    <defs>
      <clipPath id="digits-shield_svg__a">
        <rect width="100%" height="100%" />
      </clipPath>
    </defs>
  </svg>
)
