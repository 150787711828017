/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgQualityControlUnexpectedVendor = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 31 31"
    aria-label="SvgQualityControlUnexpectedVendor"
    {...props}
  >
    {props.children}
    <filter
      id="quality-control-unexpected-vendor_svg__a"
      width={20.169}
      height={20.168}
      x={4.854}
      y={4.551}
      colorInterpolationFilters="sRGB"
      filterUnits="userSpaceOnUse"
    >
      <feFlood floodOpacity={0} result="BackgroundImageFix" />
      <feGaussianBlur in="BackgroundImageFix" stdDeviation={0.5} />
      <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_18953_249966" />
      <feBlend in="SourceGraphic" in2="effect1_backgroundBlur_18953_249966" result="shape" />
    </filter>
    <path
      fill="#00a69c"
      fillOpacity={0.3}
      fillRule="evenodd"
      d="m17.173 7.405 5.8-.247c1.256-.05 2.227.9 2.185 2.14l-.066 5.932c-.097.515-.26.936-.574 1.322a5.5 5.5 0 0 0-7.662 7.666l-.57.568c-.823.804-2.171.837-2.975.014L7.67 19.13c-.803-.823-.836-2.172-.013-2.975l8.156-8.145c.365-.41.88-.6 1.36-.606m3.222 1.524c.08.59.566 1.066 1.139 1.078.665.03 1.14-.456 1.078-1.139-.08-.59-.566-1.066-1.14-1.078-.59.08-1.065.566-1.077 1.139"
      clipRule="evenodd"
    />
    <circle cx={21.354} cy={21.051} r={4.5} fill="#00a69c" transform="rotate(-90 21.354 21.05)" />
    <g filter="url(#quality-control-unexpected-vendor_svg__a)">
      <path
        fill="#00a69c"
        fillRule="evenodd"
        d="m16.101 5.84 5.843-.283c1.13-.094 2.167.942 2.073 2.073l-.189 5.936c0 .566-.188 1.037-.66 1.414l-.686.686a5.5 5.5 0 0 0-6.512 6.512l-.905.905c-.754.848-2.073.848-2.921 0L6.49 17.43c-.848-.754-.848-2.073 0-2.921l8.198-8.104c.377-.283.848-.566 1.413-.566m4.24 2.45c0 .659.566 1.13 1.131 1.13s1.131-.377 1.131-1.036-.565-1.131-1.13-1.131c-.66 0-1.131.377-1.131 1.036"
        clipRule="evenodd"
      />
    </g>
    <path fill="#c8fbfc" d="M20.884 21.641v-3.09h1v3.09zm0 1.91-.03-.963h1.052l.03.963z" />
  </svg>
)
