/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgGeneral = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={props.width || "100%"}
    height={props.height || "100%"}
    viewBox="0 0 16 16"
    aria-label="SvgGeneral"
    {...props}
  >
    {props.children}
    <path
      d="M8 14.5c-4.495 0-6.5-.771-6.5-2.5 0-2.172 2.912-5.5 6.5-5.5s6.5 3.328 6.5 5.5c0 1.729-2.005 2.5-6.5 2.5z"
      fill="none"
      stroke="#000"
      strokeMiterlimit="10"
    />
    <path
      d="M2.293 6.592C3.827 5.378 5.797 4.524 8 4.524s4.172.853 5.706 2.067"
      fill="none"
      stroke="#000"
      strokeMiterlimit="10"
    />
    <circle cx={6} cy={2} r={1} />
    <path
      d="M9.5 2c0 1.381-.672 2.5-1.5 2.5S6.5 3.381 6.5 2"
      fill="none"
      stroke="#000"
      strokeMiterlimit="10"
    />
    <circle cx={10} cy={2} r={1} />
  </svg>
)
