/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgTag03Solid = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 24 24"
    aria-label="SvgTag03Solid"
    {...props}
  >
    {props.children}
    <g fill="#000">
      <path d="M5 2a1 1 0 0 1 1-1h4.114c.635 0 1.115 0 1.577.11a4 4 0 0 1 1.156.48c.406.248.745.587 1.194 1.037l7.666 7.666a1 1 0 1 1-1.414 1.414l-7.594-7.594c-.553-.553-.718-.709-.897-.818a2 2 0 0 0-.578-.24c-.203-.049-.43-.055-1.212-.055H6a1 1 0 0 1-1-1" />
      <path d="M3.638 4.827a3 3 0 0 0-1.31 1.311c-.194.378-.265.772-.297 1.167C2 7.68 2 8.135 2 8.662L2 10.758c0 .407 0 .766.083 1.114a3 3 0 0 0 .36.867c.186.306.44.56.728.847l7.887 7.886c.372.373.694.694.98.937.303.257.631.485 1.035.616a3 3 0 0 0 1.854 0c.404-.131.733-.359 1.035-.616.286-.243.608-.564.98-.937l2.53-2.53c.372-.372.694-.694.937-.98.257-.302.485-.631.616-1.035a3 3 0 0 0 0-1.854c-.131-.404-.359-.732-.616-1.035a18 18 0 0 0-.937-.98L12.086 5.67c-.288-.288-.541-.542-.847-.729a3 3 0 0 0-.867-.359c-.348-.084-.707-.083-1.114-.083H6.162c-.528 0-.982 0-1.357.03-.395.033-.788.104-1.167.297" />
    </g>
  </svg>
)
