/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgProductDesign = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={props.width || "100%"}
    height={props.height || "100%"}
    viewBox="0 0 16 16"
    aria-label="SvgProductDesign"
    {...props}
  >
    {props.children}
    <path
      d="M4 13.5h-.5a1 1 0 0 1-1-1v-9a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1V4"
      fill="none"
      stroke="#000"
      strokeMiterlimit="10"
    />
    <path
      d="M5.551 8.378h9.898v4.242H5.551z"
      transform="rotate(-45.001 10.5 10.5)"
      fill="none"
      stroke="#000"
      strokeLinejoin="round"
      strokeMiterlimit="10"
    />
    <path
      d="M12.5 8.5 11 7M10.625 10.625l-1.5-1.5M8.625 12.625l-1.5-1.5"
      fill="none"
      stroke="#000"
      strokeLinejoin="round"
      strokeMiterlimit="10"
    />
    <circle cx={6.5} cy={4.5} r={0.5} />
    <circle cx={8.5} cy={6.5} r={0.5} />
    <circle cx={8.5} cy={6.5} r={0.5} />
    <circle cx={6.5} cy={6.5} r={0.5} />
    <circle cx={6.5} cy={8.5} r={0.5} />
    <circle cx={4.5} cy={4.5} r={0.5} />
    <circle cx={4.5} cy={6.5} r={0.5} />
    <circle cx={4.5} cy={8.5} r={0.5} />
    <circle cx={4.5} cy={10.5} r={0.5} />
    <circle cx={8.5} cy={4.5} r={0.5} />
    <circle cx={10.5} cy={4.5} r={0.5} />
  </svg>
)
