/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgAccumulatedAmortization = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={props.width || "100%"}
    height={props.height || "100%"}
    viewBox="0 0 16 16"
    aria-label="SvgAccumulatedAmortization"
    {...props}
  >
    {props.children}
    <path
      d="M3.5 1.5h9M3.5 13.5h9M4.5 1.5V4a3.5 3.5 0 1 0 7 0V1.5"
      fill="none"
      stroke="#000"
      strokeLinecap="square"
      strokeMiterlimit="10"
    />
    <path
      d="M4.5 13.5V11a3.5 3.5 0 1 1 7 0v2.5"
      fill="none"
      stroke="#000"
      strokeLinecap="square"
      strokeMiterlimit="10"
    />
    <path d="M8 10c-1.103 0-2 .897-2 2v2h4v-2c0-1.103-.897-2-2-2" />
  </svg>
)
