/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgHome01Solid = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 24 24"
    aria-label="SvgHome01Solid"
    {...props}
  >
    {props.children}
    <path
      fill="#000"
      fillRule="evenodd"
      d="M11.477 1.336a2 2 0 0 1 1.046 0c.397.108.732.37.999.58l.074.059 6.782 5.275.078.06c.377.292.709.55.956.884.217.294.379.624.477.976.112.4.112.82.111 1.297v7.372c0 .527 0 .982-.03 1.356-.033.395-.104.789-.297 1.167a3 3 0 0 1-1.311 1.311c-.378.193-.772.264-1.167.296-.375.031-.83.031-1.356.031H6.16c-.527 0-.982 0-1.356-.03-.395-.033-.789-.104-1.167-.297a3 3 0 0 1-1.311-1.311c-.193-.378-.264-.772-.297-1.167-.03-.374-.03-.83-.03-1.356v-7.372c0-.477-.001-.897.11-1.297a3 3 0 0 1 .478-.976c.247-.334.579-.592.956-.884l.077-.06 6.783-5.275.074-.059c.267-.21.602-.472 1-.58"
      clipRule="evenodd"
    />
  </svg>
)
