/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgArrowBlockLeft = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 24 24"
    aria-label="SvgArrowBlockLeft"
    {...props}
  >
    {props.children}
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="m3 12 7-7v4h10.2c.28 0 .42 0 .527.055a.5.5 0 0 1 .219.218C21 9.38 21 9.52 21 9.8v4.4c0 .28 0 .42-.054.527a.5.5 0 0 1-.219.219C20.62 15 20.48 15 20.2 15H10v4z"
    />
  </svg>
)
