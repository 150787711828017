/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgDigitsFullLogo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 354 100"
    aria-label="SvgDigitsFullLogo"
    {...props}
  >
    {props.children}
    <clipPath id="digits-full-logo_svg__a">
      <path d="M128.058 0h225.626v100H128.058z" />
    </clipPath>
    <g fill="#000">
      <path
        fillRule="evenodd"
        d="M89.925 78.306c-1.2 1.476-3.494 1.581-4.912.423-1.526-1.159-1.638-3.373-.437-4.745 5.566-6.536 8.948-14.758 8.948-23.929.109-15.497-9.714-28.781-23.465-34.474 0 0-.108 0-.108-.105-1.747-.738-2.51-2.74-1.747-4.426.763-1.687 2.839-2.425 4.583-1.687 16.372 6.746 27.83 22.455 27.83 40.587 0 10.752-4.04 20.664-10.695 28.358zM57.509 12.734l-24.12-.106h-.108a3.48 3.48 0 0 1-3.494-3.478c.11-1.791 1.638-3.268 3.602-3.268H54.89c.984 0 2.073 0 3.056.105 1.856.106 3.274 1.477 3.274 3.268v.423c0 1.792-1.747 3.268-3.71 3.058zM74.425 25.91c.763.633 1.309 1.581 1.418 2.53 0 .843-.218 1.686-.764 2.319-1.2 1.476-3.382 1.687-4.911.528-4.148-3.163-9.386-4.955-15.06-4.955H14.836a3.48 3.48 0 0 1-3.494-3.478c0-1.897 1.638-3.373 3.602-3.373H55c7.313 0 14.08 2.425 19.428 6.431zM72.46 49.947c0 9.279-7.86 17.078-17.57 16.973H41.684a3.48 3.48 0 0 1-3.493-3.478V49.947c0-1.896 1.527-3.373 3.493-3.478h.11a3.48 3.48 0 0 1 3.492 3.478v10.12h9.606c5.894 0 10.477-4.534 10.477-10.12s-4.694-10.119-10.477-10.119H6.543a3.477 3.477 0 0 1-3.494-3.478c0-1.897 1.638-3.373 3.602-3.373h48.348c9.605 0 17.462 7.697 17.462 16.973zm-46.602 3.479H15.053c-1.964 0-3.493-1.582-3.382-3.479 0-1.896 1.638-3.373 3.602-3.268h10.695c1.964 0 3.493 1.582 3.493 3.374v.105c-.108 1.896-1.638 3.373-3.602 3.268M7.09 60.172H25.97c1.964 0 3.494 1.476 3.602 3.373v.105c0 1.897-1.638 3.373-3.602 3.373H6.977a3.48 3.48 0 0 1-3.493-3.478c.108-1.897 1.746-3.373 3.602-3.373zm7.75 13.495h40.054c13.642 0 24.557-10.543 24.557-23.72 0-2.74-.546-5.377-1.418-7.907-.655-1.791.328-3.69 2.184-4.321 1.855-.633 3.82.315 4.474 2.11a28.7 28.7 0 0 1 1.855 10.118c0 16.868-14.188 30.468-31.54 30.468H14.726a3.48 3.48 0 0 1-3.493-3.478c.108-1.792 1.746-3.268 3.602-3.268zm18.662 13.6H55a40.1 40.1 0 0 0 18.554-4.535 3.2 3.2 0 0 1 1.638-.423c1.964 0 3.493 1.477 3.602 3.374a3.4 3.4 0 0 1-1.855 3.057c-6.438 3.479-13.971 5.378-21.938 5.378h-21.61a3.48 3.48 0 0 1-3.493-3.478c.108-1.897 1.638-3.374 3.602-3.374"
        clipRule="evenodd"
      />
      <g clipPath="url(#digits-full-logo_svg__a)">
        <path d="M128.062 19.4h23.914c19.408 0 32.378 12.273 32.378 30.68s-12.97 30.678-32.378 30.678h-23.914zm23.73 54.434c13.981 0 23.271-9.466 23.271-23.755s-9.29-23.755-23.271-23.755h-14.717v47.508h14.717zM195.739 17.647c3.495 0 6.162 2.63 6.162 5.961s-2.667 5.962-6.162 5.962-6.254-2.543-6.254-5.962 2.667-5.96 6.254-5.96m-4.414 19.371h8.736v43.74h-8.736zM208.912 81.986l8.555-.351c.92 6.222 6.531 10.43 14.256 10.43 8.554 0 14.165-5.26 14.165-13.85V69.8c-2.575 5.522-8.921 9.642-16.371 9.642-12.327 0-22.075-9.293-22.075-21.914s9.566-21.826 22.075-21.826c7.45 0 14.163 3.944 16.74 9.906v-8.59h8.278v39.884c0 13.673-8.829 21.825-22.81 21.825-12.877 0-21.89-6.75-22.81-16.74zm22.444-9.378c8.463 0 15.083-6.31 15.083-15.075s-6.254-14.99-15.083-14.99-15.175 6.574-15.175 14.99 6.623 15.075 15.175 15.075M268.096 17.647c3.494 0 6.162 2.63 6.162 5.961s-2.668 5.962-6.162 5.962c-3.495 0-6.254-2.543-6.254-5.962s2.667-5.96 6.254-5.96m-4.415 19.371h8.737v43.74h-8.737zM289.13 67.26V43.945h-8.829v-6.927h8.829V26.063h8.736v10.955h11.865v6.927h-11.865v22.702c0 7.715 5.427 8.504 10.487 6.75l.185 6.924c-8.922 2.718-19.408.437-19.408-13.061M314.862 66.908l8.094-.264c.55 5.962 5.426 9.73 12.141 9.73 5.427 0 10.118-2.366 10.118-6.923 0-3.42-2.759-5.346-8.186-6.399l-7.817-1.402c-8.921-1.402-13.061-5.961-13.061-12.36 0-8.503 7.45-13.85 17.66-13.85s18.304 6.135 18.762 15.252l-7.817.437c-.368-5.697-4.875-9.73-11.037-9.73-5.15 0-9.29 2.719-9.29 7.1 0 3.243 2.301 5.26 6.438 5.961l7.82 1.402c9.656 1.666 14.901 5.874 14.901 12.797 0 8.416-7.909 13.586-18.488 13.586-11.499 0-19.5-5.961-20.236-15.34z" />
      </g>
    </g>
  </svg>
)
