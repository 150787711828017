/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgShieldDollarSolid = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 24 24"
    aria-label="SvgShieldDollarSolid"
    {...props}
  >
    {props.children}
    <path
      fill="#000"
      fillRule="evenodd"
      d="M11.714 1.089a2 2 0 0 1 .572 0c.219.031.422.108.584.17l.043.015 5.493 2.06c.617.23 1.16.433 1.578.801a3 3 0 0 1 .82 1.184c.198.52.197 1.1.196 1.76V12c0 2.827-1.537 5.183-3.199 6.914-1.673 1.743-3.587 2.974-4.599 3.565l-.04.024c-.186.108-.425.249-.742.317a2.1 2.1 0 0 1-.84 0c-.316-.068-.556-.209-.74-.317l-.042-.024c-1.012-.59-2.926-1.822-4.599-3.564C4.538 17.183 3 14.827 3 12V7.078c0-.66-.002-1.238.196-1.759a3 3 0 0 1 .82-1.184c.419-.368.961-.57 1.579-.801l5.492-2.06.044-.016c.161-.06.365-.138.583-.17M13 6a1 1 0 1 0-2 0v.5a3 3 0 0 0 0 6h2a1 1 0 1 1 0 2h-2.133a.87.87 0 0 1-.769-.465 1 1 0 1 0-1.77.93 2.87 2.87 0 0 0 2.539 1.535H11v.5a1 1 0 0 0 2 0v-.5a3 3 0 1 0 0-6h-2a1 1 0 0 1 0-2h2.134c.332 0 .622.186.768.465a1 1 0 0 0 1.77-.93A2.87 2.87 0 0 0 13.135 6.5H13z"
      clipRule="evenodd"
    />
  </svg>
)
