/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgClockStopwatchSolid = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 24 24"
    aria-label="SvgClockStopwatchSolid"
    {...props}
  >
    {props.children}
    <g fill="#000">
      <path
        fillRule="evenodd"
        d="M10 1a1 1 0 0 0 0 2h1v1.052c-4.777.5-8.5 4.539-8.5 9.448a9.5 9.5 0 0 0 19 0c0-4.909-3.723-8.948-8.5-9.448V3h1a1 1 0 1 0 0-2zm3 8.5a1 1 0 1 0-2 0v4a1 1 0 0 0 .486.858l2.5 1.5a1 1 0 0 0 1.028-1.716L13 12.935z"
        clipRule="evenodd"
      />
      <path d="M18.122 3.385a1 1 0 0 1 1.414 0l1.5 1.5a1 1 0 0 1-1.414 1.414l-1.5-1.5a1 1 0 0 1 0-1.414M5.878 3.385a1 1 0 0 1 0 1.414l-1.5 1.5a1 1 0 1 1-1.414-1.414l1.5-1.5a1 1 0 0 1 1.414 0" />
    </g>
  </svg>
)
