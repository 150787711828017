/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgNavigationPointer02Solid = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 24 24"
    aria-label="SvgNavigationPointer02Solid"
    {...props}
  >
    {props.children}
    <path
      fill="#000"
      d="M12.666 1.903a1.5 1.5 0 0 0-1.34 0c-.374.186-.567.514-.658.681-.1.182-.203.416-.306.651l-7.311 16.67c-.115.262-.227.516-.298.727-.062.182-.187.575-.053.998a1.5 1.5 0 0 0 .98.979c.423.133.816.007.998-.055.21-.071.465-.183.727-.298l6.59-2.9 6.592 2.9c.262.115.516.227.727.299.182.061.575.187.998.054a1.5 1.5 0 0 0 .98-.979c.133-.423.008-.816-.053-.998a10 10 0 0 0-.298-.727L13.63 3.235a9 9 0 0 0-.306-.65c-.092-.168-.285-.496-.658-.682"
    />
  </svg>
)
