import { css } from "styled-components"

export const svgIconStyles = (color: string, transition?: boolean) => css`
  fill: ${color};

  g:not([fill="none"]) {
    fill: ${color};
  }

  path {
    transition: ${transition ? "fill 250ms ease" : "none"};
  }

  *[stroke-miterlimit],
  circle,
  ellipse {
    transition: ${transition ? "stroke 250ms ease" : "none"};
    stroke: ${color};
  }

  path[stroke]:not([stroke="none"]) {
    stroke: ${color};
  }

  path[fill]:not([fill="none"]) {
    fill: ${color};
  }

  path[fill="#010101"] {
    fill: ${color};
  }
`

export const svgPathStyles = (color: string, strokeWidth?: number, transition?: boolean) => css`
  path,
  rect,
  circle {
    stroke: ${color};
    transition: ${transition ? "stroke 250ms ease" : "none"};

    ${strokeWidth &&
    css`
      stroke-width: ${strokeWidth}px;
    `};
  }
`
