/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgTravel = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    viewBox="0 0 16 16"
    aria-label="SvgTravel"
    {...props}
  >
    {props.children}
    <path d="M7.5 1C6.676 1 6 1.676 6 2.5v2.719l-5 3v1.89l5-1v2.141L5 12v1.98l2.5-1.406L10 13.98V12l-1-.75V9.11l5 1V8.218l-5-3V2.5C9 1.676 8.324 1 7.5 1m0 1c.281 0 .5.219.5.5v3.281l5 3v.11l-5-1v3.816l-.5-.281-.5.281V7.891l-5 1v-.11l5-3V2.5c0-.281.219-.5.5-.5" />
  </svg>
)
