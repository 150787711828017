/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgAccountsPayable = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={props.width || "100%"}
    height={props.height || "100%"}
    viewBox="0 0 16 16"
    aria-label="SvgAccountsPayable"
    {...props}
  >
    {props.children}
    <path
      d="M12.5 13.51h-9c-.553 0-1-.474-1-1.059V5.569c0-.585.447-1.059 1-1.059h9c.552 0 1 .474 1 1.059v6.882c0 .586-.448 1.059-1 1.059z"
      fill="none"
      stroke="#000"
      strokeMiterlimit="10"
    />
    <path
      d="M2.5 7.351V4.622c0-.455.29-.851.706-.967L10.625 1.5c.5-.125.875.25.875.75V3"
      fill="none"
      stroke="#000"
      strokeMiterlimit="10"
    />
    <circle cx={11} cy={9} r={1} />
  </svg>
)
