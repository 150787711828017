/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgTransactionReviewZeroStateReplies = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 295 295"
    aria-label="SvgTransactionReviewZeroStateReplies"
    {...props}
  >
    {props.children}
    <circle
      cx={147.5}
      cy={147.5}
      r={105.5}
      fill="url(#transaction-review-zero-state-replies_svg__a)"
    />
    <circle cx={147.5} cy={147.5} r={123.5} stroke="#D4FFFE" strokeOpacity={0.1} strokeWidth={10} />
    <circle cx={147.5} cy={147.5} r={145.5} stroke="#D4FFFE" strokeOpacity={0.04} strokeWidth={4} />
    <g clipPath="url(#transaction-review-zero-state-replies_svg__b)">
      <path
        fill="url(#transaction-review-zero-state-replies_svg__c)"
        d="M97.606 205v-14.938h-6.028c-4.133 0-7.578-3.356-7.578-7.385v-30.715c0-4.028 3.445-7.385 7.578-7.385h54.251c4.134 0 7.578 3.357 7.578 7.385v30.715c0 4.029-3.444 7.385-7.578 7.385h-26.695z"
      />
      <path
        fill="#3E6D70"
        d="M106.029 172.271a4.406 4.406 0 1 0 0-8.812 4.406 4.406 0 0 0 0 8.812M118.617 172.271a4.406 4.406 0 1 0 0-8.812 4.406 4.406 0 0 0 0 8.812M131.206 172.271a4.406 4.406 0 1 0 0-8.813 4.406 4.406 0 0 0 0 8.813"
      />
    </g>
    <g clipPath="url(#transaction-review-zero-state-replies_svg__d)">
      <path
        fill="#49D2C3"
        d="M193.577 174.788v-18.984h7.781c5.336 0 9.783-4.266 9.783-9.385v-39.034c0-5.119-4.447-9.385-9.783-9.385h-70.033c-5.336 0-9.783 4.266-9.783 9.385v39.034c0 5.119 4.447 9.385 9.783 9.385h34.461z"
      />
      <path
        fill="url(#transaction-review-zero-state-replies_svg__e)"
        d="M193.577 174.788v-18.984h7.781c5.336 0 9.783-4.266 9.783-9.385v-39.034c0-5.119-4.447-9.385-9.783-9.385h-70.033c-5.336 0-9.783 4.266-9.783 9.385v39.034c0 5.119 4.447 9.385 9.783 9.385h34.461z"
      />
      <path
        fill="#3E6D70"
        d="M182.817 131.989a5.665 5.665 0 1 1 .002-11.33 5.665 5.665 0 0 1-.002 11.33M166.453 131.989a5.665 5.665 0 1 1 0-11.33 5.665 5.665 0 0 1 0 11.33M150.088 131.989a5.665 5.665 0 1 1 0-11.33 5.665 5.665 0 0 1 0 11.33"
      />
    </g>
    <defs>
      <linearGradient
        id="transaction-review-zero-state-replies_svg__a"
        x1={147.5}
        x2={147.5}
        y1={42}
        y2={253}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#71F5BB" />
        <stop offset={1} stopColor="#10D4EF" stopOpacity={0.61} />
      </linearGradient>
      <linearGradient
        id="transaction-review-zero-state-replies_svg__c"
        x1={118.704}
        x2={115.972}
        y1={144.577}
        y2={210.502}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#fff" stopOpacity={0.67} />
        <stop offset={1} stopColor="#fff" />
      </linearGradient>
      <linearGradient
        id="transaction-review-zero-state-replies_svg__e"
        x1={166.341}
        x2={169.759}
        y1={98}
        y2={181.783}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#fff" stopOpacity={0.67} />
        <stop offset={1} stopColor="#fff" />
      </linearGradient>
      <clipPath id="transaction-review-zero-state-replies_svg__b">
        <path fill="#fff" d="M84 144.577h69.235V205H84z" />
      </clipPath>
      <clipPath id="transaction-review-zero-state-replies_svg__d">
        <path fill="#fff" d="M211.141 98h-89.376v76.788h89.376z" />
      </clipPath>
    </defs>
  </svg>
)
