/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgKybcPartnership = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 31 23"
    aria-label="SvgKybcPartnership"
    {...props}
  >
    {props.children}
    <g clipPath="url(#kybc-partnership_svg__a)">
      <path
        fill="url(#kybc-partnership_svg__b)"
        d="m16.447 20.388-6.69-5.761 6.616-8.049 4.204-.681 4.458 4.413-3.838 6.152-4.75 3.925"
      />
      <path
        fill="#E5A642"
        d="M13.382 20.25a1.03 1.03 0 0 1 1.463 0 1.045 1.045 0 0 1 0 1.47 1.03 1.03 0 0 1-1.463 0 1.045 1.045 0 0 1 0-1.47m-5.185-5.218a1.027 1.027 0 0 1 1.462 0 1.046 1.046 0 0 1 0 1.47 1.03 1.03 0 0 1-1.462 0 1.045 1.045 0 0 1 0-1.47"
      />
      <path fill="#E8563F" d="m20.551 5.905.026-.003-.03-.029z" />
      <path
        fill="#fff"
        d="M21.818 15.136 17.19 10.48l-.002-.002-.706-.712-5.828-4.15-4.693 4.721 2.659 5.43 4.052 3.59.018-.02 2.155 2.17a1.03 1.03 0 0 0 1.573-.13c.3-.417.23-.998-.132-1.361l-1.99-2.003a.19.19 0 1 1 .267-.27l2.285 2.298a1.03 1.03 0 0 0 1.462 0 1.045 1.045 0 0 0 0-1.47l-2.274-2.29a.19.19 0 0 1 0-.27.19.19 0 0 1 .266 0l2.503 2.519a1.03 1.03 0 0 0 1.461 0 1.044 1.044 0 0 0 0-1.471l-2.492-2.508a.19.19 0 0 1 0-.27.19.19 0 0 1 .267 0l2.309 2.324a1.03 1.03 0 0 0 1.462 0 1.045 1.045 0 0 0 0-1.471z"
      />
      <path
        fill="#fff"
        d="M14.57 18.524a1.045 1.045 0 0 0 0-1.47 1.03 1.03 0 0 0-1.462 0l-1.557 1.566a1.045 1.045 0 0 0 0 1.471 1.027 1.027 0 0 0 1.462 0zM15.799 19.288a1.03 1.03 0 0 0-1.462 0l-1.06 1.067a1.045 1.045 0 0 0 0 1.471 1.03 1.03 0 0 0 1.461 0l1.06-1.067a1.043 1.043 0 0 0 0-1.471M12.936 16.699c.538-.542.538-1.2.134-1.607s-1.058-.406-1.596.135l-1.648 1.658a1.045 1.045 0 0 0 0 1.47 1.03 1.03 0 0 0 1.462 0l1.647-1.657zM9.554 16.61l1.38-1.39a1.044 1.044 0 0 0 0-1.47 1.03 1.03 0 0 0-1.461 0L8.09 15.14a1.045 1.045 0 0 0 0 1.47 1.03 1.03 0 0 0 1.462 0zM25.227 1.624a1.39 1.39 0 0 0-1.973 0l-2.491 2.509a1.39 1.39 0 0 0 0 1.96l4.142 4.168a1.39 1.39 0 0 0 1.973 0l2.492-2.507a1.39 1.39 0 0 0 0-1.96zM10.37 6.05a1.39 1.39 0 0 0 0-1.96L7.327 1.03a.65.65 0 0 0-.926 0L1.736 5.724a1.39 1.39 0 0 0 0 1.96l2.519 2.535a1.39 1.39 0 0 0 1.972 0z"
      />
    </g>
    <defs>
      <linearGradient
        id="kybc-partnership_svg__b"
        x1={23.121}
        x2={9.218}
        y1={7.518}
        y2={20.233}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.167} stopColor="#fff" />
        <stop offset={1} stopColor="#D9D9D9" />
      </linearGradient>
      <clipPath id="kybc-partnership_svg__a">
        <path fill="#fff" d="M.762.563h29.582v21.57H.762z" />
      </clipPath>
    </defs>
  </svg>
)
