/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgDotsVerticalSolid = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 24 24"
    aria-label="SvgDotsVerticalSolid"
    {...props}
  >
    {props.children}
    <g fill="#000" fillRule="evenodd" clipRule="evenodd">
      <path d="M10 12a2 2 0 1 1 4 0 2 2 0 0 1-4 0M10 5a2 2 0 1 1 4 0 2 2 0 0 1-4 0M10 19a2 2 0 1 1 4 0 2 2 0 0 1-4 0" />
    </g>
  </svg>
)
