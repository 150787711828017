/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgFileX03Solid = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 24 24"
    aria-label="SvgFileX03Solid"
    {...props}
  >
    {props.children}
    <g fill="#000">
      <path
        fillRule="evenodd"
        d="m13 2.016-.001-.064a1.03 1.03 0 0 0-.952-.95L12 1H8.759c-.805 0-1.47 0-2.01.044-.563.046-1.08.145-1.565.392a4 4 0 0 0-1.748 1.748c-.247.485-.346 1.002-.392 1.564C3 5.29 3 5.954 3 6.758v10.483c0 .805 0 1.47.044 2.01.046.563.145 1.08.392 1.565a4 4 0 0 0 1.748 1.748c.485.247 1.002.346 1.564.392C7.29 23 7.954 23 8.758 23h6.483c.805 0 1.47 0 2.01-.044.563-.046 1.08-.145 1.565-.392a4 4 0 0 0 1.748-1.748c.247-.485.346-1.002.392-1.564.044-.541.044-1.206.044-2.01V10l-.001-.047A1.03 1.03 0 0 0 20.048 9L19.985 9h-4.417c-.252 0-.498 0-.706-.017a2 2 0 0 1-.77-.201 2 2 0 0 1-.874-.874 2 2 0 0 1-.201-.77C13 6.93 13 6.684 13 6.432zM8.793 12.293a1 1 0 0 1 1.414 0L12 14.086l1.793-1.793a1 1 0 0 1 1.414 1.414L13.414 15.5l1.793 1.793a1 1 0 0 1-1.414 1.414L12 16.914l-1.793 1.793a1 1 0 0 1-1.414-1.414l1.793-1.793-1.793-1.793a1 1 0 0 1 0-1.414"
        clipRule="evenodd"
      />
      <path d="M18.908 7c.294 0 .44 0 .56-.074a.54.54 0 0 0 .226-.543c-.033-.137-.13-.233-.32-.424L16.04 2.627c-.191-.192-.287-.288-.424-.32a.54.54 0 0 0-.543.224c-.074.12-.074.267-.074.561V6.2c0 .28 0 .42.055.527a.5.5 0 0 0 .218.218C15.38 7 15.52 7 15.8 7z" />
    </g>
  </svg>
)
