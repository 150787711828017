/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgPin02Solid = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 24 24"
    aria-label="SvgPin02Solid"
    {...props}
  >
    {props.children}
    <path
      fill="#000"
      fillRule="evenodd"
      d="M16.384 2.41c-.256-.256-.494-.493-.706-.67-.222-.185-.52-.395-.91-.474a2 2 0 0 0-1.494.287c-.333.219-.531.524-.669.779-.131.243-.263.552-.406.884l-1.085 2.53-.045.105v.002h-.002l-.08.082-1.56 1.56-.128.126-.002.002h-.002l-.175.037-3.707.741c-.44.088-.835.167-1.146.26-.31.092-.711.243-1.015.585a2 2 0 0 0-.485 1.602c.063.453.313.802.52 1.051s.492.534.81.851l2.865 2.866-4.95 4.95a1 1 0 1 0 1.415 1.414l4.95-4.95 2.866 2.867c.317.317.602.602.851.81.249.206.598.456 1.05.519a2 2 0 0 0 1.603-.485c.342-.304.494-.705.586-1.015s.171-.706.259-1.146l.741-3.707.037-.175v-.002l.002-.002.125-.127 1.561-1.561.081-.08.001-.001.002-.001c.02-.01.047-.021.104-.045l2.53-1.085c.333-.142.642-.275.885-.406.255-.137.56-.336.779-.669a2 2 0 0 0 .287-1.494c-.079-.39-.289-.688-.474-.91-.177-.212-.414-.45-.67-.706z"
      clipRule="evenodd"
    />
  </svg>
)
