/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgSearchCustomer = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 31 31"
    aria-label="SvgSearchCustomer"
    {...props}
  >
    {props.children}
    <g filter="url(#search_customer_svg__a)" opacity={0.5}>
      <path
        fill="url(#search_customer_svg__b)"
        fillRule="evenodd"
        d="M23.719 12.32c0-2.052-1.738-3.718-3.877-3.718-2.14 0-3.877 1.666-3.877 3.717s1.737 3.718 3.877 3.718c2.145 0 3.877-1.666 3.877-3.718m2.699 9.305c-1.056-2.892-3.603-4.934-6.575-4.934s-5.52 2.036-6.576 4.929c1.704 1.58 4.023 2.55 6.576 2.55s4.871-.97 6.575-2.545"
        clipRule="evenodd"
      />
    </g>
    <g filter="url(#search_customer_svg__c)">
      <path
        fill="url(#search_customer_svg__d)"
        fillRule="evenodd"
        d="M19.525 10.75c0-2.76-2.24-5-5-5s-5 2.24-5 5 2.24 5 5 5c2.767 0 5-2.24 5-5m3 12.597c-1.365-3.868-4.658-6.597-8.5-6.597s-7.135 2.722-8.5 6.59a12.24 12.24 0 0 0 8.5 3.41c3.3 0 6.298-1.297 8.5-3.403"
        clipRule="evenodd"
      />
    </g>
    <g filter="url(#search_customer_svg__e)">
      <path
        fill="#00A69C"
        fillRule="evenodd"
        d="M19.632 10.765c0-2.768-2.344-5.015-5.23-5.015s-5.23 2.247-5.23 5.015 2.343 5.015 5.23 5.015c2.893 0 5.23-2.247 5.23-5.015m3.639 12.553c-1.425-3.903-4.861-6.656-8.87-6.656-4.01 0-7.446 2.746-8.87 6.649 2.298 2.131 5.426 3.44 8.87 3.44s6.571-1.309 8.87-3.433"
        clipRule="evenodd"
      />
    </g>
    <defs>
      <filter
        id="search_customer_svg__a"
        width={21.151}
        height={23.566}
        x={9.267}
        y={4.602}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation={2} />
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_18532_229008" />
        <feBlend in="SourceGraphic" in2="effect1_backgroundBlur_18532_229008" result="shape" />
      </filter>
      <filter
        id="search_customer_svg__c"
        width={25}
        height={29}
        x={1.525}
        y={1.75}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation={2} />
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_18532_229008" />
        <feBlend in="SourceGraphic" in2="effect1_backgroundBlur_18532_229008" result="shape" />
      </filter>
      <filter
        id="search_customer_svg__e"
        width={19.739}
        height={23}
        x={4.531}
        y={4.75}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation={0.5} />
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_18532_229008" />
        <feBlend in="SourceGraphic" in2="effect1_backgroundBlur_18532_229008" result="shape" />
      </filter>
      <linearGradient
        id="search_customer_svg__b"
        x1={22.348}
        x2={17.085}
        y1={19.4}
        y2={9.184}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#018F68" />
        <stop offset={0.865} stopColor="#02AF7F" />
      </linearGradient>
      <linearGradient
        id="search_customer_svg__d"
        x1={17.263}
        x2={9.991}
        y1={20.316}
        y2={6.788}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#9AF9FF" stopOpacity={0.69} />
        <stop offset={0.865} stopColor="#D4FEFD" />
      </linearGradient>
    </defs>
  </svg>
)
