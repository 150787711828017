/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgAmortization = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={props.width || "100%"}
    height={props.height || "100%"}
    viewBox="0 0 16 16"
    aria-label="SvgAmortization"
    {...props}
  >
    {props.children}
    <path
      d="M13.38 1.5H1.62c-.863 0-1.401.912-.966 1.638l5.88 9.821c.431.721 1.5.721 1.932 0l5.88-9.821c.435-.726-.103-1.638-.966-1.638zM2.125 5.5H13M4.5 9.5h6.125"
      fill="none"
      stroke="#000"
      strokeMiterlimit="10"
    />
    <path d="m7.5 13.5 3-4h-6z" />
    <circle cx={4.5} cy={6.5} r={0.5} />
    <circle cx={6.5} cy={6.5} r={0.5} />
    <circle cx={8.5} cy={6.5} r={0.5} />
    <circle cx={10.5} cy={6.5} r={0.5} />
    <circle cx={9.5} cy={8.5} r={0.5} />
    <circle cx={7.5} cy={8.5} r={0.5} />
    <circle cx={5.5} cy={8.5} r={0.5} />
  </svg>
)
