/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgSearchPrepaidVendor = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 31 31"
    aria-label="SvgSearchPrepaidVendor"
    {...props}
  >
    {props.children}
    <path
      fill="#00A69C"
      fillOpacity={0.3}
      fillRule="evenodd"
      d="m22.644 7.357-5.8.247a1.85 1.85 0 0 0-1.362.606l-8.155 8.145c-.823.803-.79 2.152.013 2.975L12.982 25c.804.823 2.152.79 2.975-.013l.391-.39-2.116-2.093a1 1 0 0 1-.008-1.415l1.614-1.632a1 1 0 0 1 1.414-.008l2.136 2.112 4.725-4.72c.365-.41.545-.855.65-1.41l.066-5.934c.042-1.238-.929-2.189-2.185-2.139m-1.44 2.85c-.573-.013-1.058-.489-1.138-1.08.012-.572.487-1.058 1.078-1.138.573.012 1.058.488 1.139 1.078.062.683-.413 1.169-1.079 1.14"
      clipRule="evenodd"
    />
    <path
      fill="url(#search_prepaid_vendor_svg__a)"
      fillOpacity={0.8}
      fillRule="evenodd"
      d="m21.614 5.756-5.842.283c-.565 0-1.036.282-1.413.565L6.16 14.708c-.848.848-.848 2.167 0 2.92l5.654 5.654c.848.849 2.167.849 2.92 0l.148-.146-.11-.107a1 1 0 0 1-.023-1.413l1.645-1.7a1 1 0 0 1 1.414-.023l.16.156 4.87-4.87c.472-.377.66-.848.66-1.414l.189-5.936c.094-1.13-.943-2.167-2.074-2.073m-.47 3.863c-.566 0-1.131-.47-1.131-1.13s.47-1.037 1.13-1.037c.566 0 1.131.471 1.131 1.13 0 .66-.565 1.037-1.13 1.037"
      clipRule="evenodd"
    />
    <path
      fill="url(#search_prepaid_vendor_svg__b)"
      fillOpacity={0.8}
      fillRule="evenodd"
      d="m21.614 5.932-5.842.282c-.565 0-1.036.283-1.413.566L6.16 14.884c-.848.848-.848 2.167 0 2.92l5.654 5.654c.656.656 1.593.805 2.333.446l.745-.745-.202-.197a1 1 0 0 1-.02-1.414l1.602-1.649a1 1 0 0 1 1.414-.02l.247.24 5.421-5.422c.1-.23.144-.482.144-.756l.189-5.936c.094-1.131-.943-2.167-2.074-2.073m-.47 3.863c-.566 0-1.131-.471-1.131-1.13 0-.66.47-1.037 1.13-1.037.566 0 1.131.471 1.131 1.13 0 .66-.565 1.037-1.13 1.037"
      clipRule="evenodd"
    />
    <g filter="url(#search_prepaid_vendor_svg__c)">
      <path
        fill="#00A69C"
        fillRule="evenodd"
        d="m21.614 5.756-5.842.283c-.565 0-1.036.282-1.413.565L6.16 14.708c-.848.848-.848 2.167 0 2.92l5.654 5.654c.848.849 2.167.849 2.92 0l.127-.125-.15-.147a1 1 0 0 1-.008-1.415l1.643-1.662a1 1 0 0 1 1.414-.008l.167.165 4.911-4.911c.472-.377.66-.848.66-1.414l.189-5.936c.094-1.13-.943-2.167-2.074-2.073m-.47 3.863c-.566 0-1.131-.47-1.131-1.13s.47-1.037 1.13-1.037c.566 0 1.131.471 1.131 1.13 0 .66-.565 1.037-1.13 1.037"
        clipRule="evenodd"
      />
    </g>
    <g filter="url(#search_prepaid_vendor_svg__d)">
      <path
        fill="#00A69C"
        fillRule="evenodd"
        d="m26.787 16.75 1.738 1.688-8.56 8.312-4.44-4.312 1.738-1.688 2.702 2.625z"
        clipRule="evenodd"
      />
    </g>
    <defs>
      <linearGradient
        id="search_prepaid_vendor_svg__a"
        x1={18.07}
        x2={12.55}
        y1={18.352}
        y2={5.668}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#9AF9FF" stopOpacity={0.69} />
        <stop offset={0.865} stopColor="#D4FEFD" />
      </linearGradient>
      <linearGradient
        id="search_prepaid_vendor_svg__b"
        x1={18.07}
        x2={12.55}
        y1={18.528}
        y2={5.844}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#9AF9FF" stopOpacity={0.69} />
        <stop offset={0.865} stopColor="#D4FEFD" />
      </linearGradient>
      <filter
        id="search_prepaid_vendor_svg__c"
        width={20.169}
        height={20.168}
        x={4.525}
        y={4.75}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation={0.5} />
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_18532_229005" />
        <feBlend in="SourceGraphic" in2="effect1_backgroundBlur_18532_229005" result="shape" />
      </filter>
      <filter
        id="search_prepaid_vendor_svg__d"
        width={15}
        height={12}
        x={14.525}
        y={15.75}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation={0.5} />
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_18532_229005" />
        <feBlend in="SourceGraphic" in2="effect1_backgroundBlur_18532_229005" result="shape" />
      </filter>
    </defs>
  </svg>
)
