/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgArrowNarrowUpRight = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 24 24"
    aria-label="SvgArrowNarrowUpRight"
    {...props}
  >
    {props.children}
    <path stroke="#000" strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6m0 0h-8m8 0v8" />
  </svg>
)
