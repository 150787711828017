/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgOrderedList = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 24 24"
    aria-label="SvgOrderedList"
    {...props}
  >
    {props.children}
    <g fill="#000">
      <path d="m3.146 5.616.662-.583V7.55h.671v-3.3h-.652l-1.077.909zM8.75 5.25a.75.75 0 0 0 0 1.5H21a.75.75 0 0 0 0-1.5zM8.75 11.25a.75.75 0 0 0 0 1.5H21a.75.75 0 0 0 0-1.5zM8 18a.75.75 0 0 1 .75-.75H21a.75.75 0 0 1 0 1.5H8.75A.75.75 0 0 1 8 18M2.75 12.865l1.282-1.151q.097-.09.196-.2c.099-.11.098-.162.098-.262a.31.31 0 0 0-.122-.26.44.44 0 0 0-.284-.099q-.195 0-.308.121a.5.5 0 0 0-.12.299l-.7-.052q.014-.255.107-.442.093-.192.247-.317a.8.8 0 0 1 .36-.187 1.5 1.5 0 0 1 .87 0 .9.9 0 0 1 .341.187q.145.12.224.307.084.188.084.434 0 .159-.033.289a1.012 1.012 0 0 1-.228.424q-.079.09-.173.173l-1.002.862h1.464v.615H2.75zM3.836 17.607h-.228v.606h.163q.116 0 .233.009a.6.6 0 0 1 .21.042.332.332 0 0 1 .21.33.44.44 0 0 1-.112.308q-.108.117-.336.117-.225 0-.34-.103a.5.5 0 0 1-.15-.28l-.736.173q.102.443.42.648.321.205.764.205.243 0 .457-.06.22-.06.377-.182a.92.92 0 0 0 .354-.746.9.9 0 0 0-.041-.275q-.042-.13-.121-.233c-.08-.103-.119-.126-.196-.173a.6.6 0 0 0-.261-.088v-.014a.66.66 0 0 0 .42-.252.77.77 0 0 0 .144-.47.85.85 0 0 0-.093-.41.8.8 0 0 0-.243-.285 1 1 0 0 0-.359-.168 1.685 1.685 0 0 0-.82-.01q-.187.043-.34.136a.73.73 0 0 0-.266.247q-.112.149-.168.364l.77.158a.406.406 0 0 1 .41-.308q.171 0 .288.098.122.098.122.247a.36.36 0 0 1-.047.191.34.34 0 0 1-.126.112.6.6 0 0 1-.168.052 1.3 1.3 0 0 1-.19.014" />
    </g>
  </svg>
)
