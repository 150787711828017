/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgHexagon01 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 24 24"
    aria-label="SvgHexagon01"
    {...props}
  >
    {props.children}
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M11.223 2.432c.284-.158.425-.237.576-.268a1 1 0 0 1 .403 0c.15.031.291.11.575.268l7.4 4.11c.3.167.45.25.558.369a1 1 0 0 1 .214.364c.051.152.051.324.051.666v8.118c0 .342 0 .513-.05.666a1 1 0 0 1-.215.364c-.109.119-.258.202-.558.368l-7.4 4.111c-.284.158-.425.237-.575.267q-.202.042-.404 0c-.15-.03-.291-.11-.575-.267l-7.4-4.11c-.3-.167-.45-.25-.558-.369a1 1 0 0 1-.215-.364C3 16.572 3 16.401 3 16.058V7.941c0-.342 0-.514.05-.666a1 1 0 0 1 .215-.364c.109-.119.258-.202.558-.368z"
    />
  </svg>
)
