/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgDigitsLogoGreen = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    id="digits-logo-green_svg__Layer_1"
    x={0}
    y={0}
    viewBox="0 0 640 640"
    aria-label="SvgDigitsLogoGreen"
    width={props.width || "100%"}
    height={props.height || "100%"}
    {...props}
  >
    {props.children}
    <style>{".digits-logo-green_svg__st0{fill:#00c4b8}"}</style>
    <path
      d="M96.9 269.2h237.7c28.8 0 51.9 23.1 51.9 51.9S363.4 373 334.6 373c-15.4 0-31.3-.5-46.7-1.5v-49.3c0-9.8-7.7-17.5-17.5-17.5h-.5c-9.8 0-17.5 8.2-17.5 17.5v65.2c0 1.5.5 3.6.5 5.1 2.1 7.2 7.7 12.3 15.4 12.8h.5c21.6 1.5 43.6 2.1 65.2 2.1 47.2 0 86.3-39 86.3-86.3 0-47.2-39-86.3-86.3-86.3H96.4c-9.8 0-17.5 7.7-17.5 16.9.5 9.8 8.2 17.5 18 17.5M137.4 200.4q.75 0 0 0h197.2c28.2 0 53.9 9.8 74.5 25.7 7.2 6.2 18.5 4.6 24.1-2.6 2.6-3.1 4.1-7.7 3.6-11.8-.5-5.1-2.6-9.8-6.7-12.8-26.2-20.5-59.6-32.9-95.5-32.9H137.9c-9.8 0-17.5 7.7-17.5 16.9.1 9.3 7.8 17 17 17.5M228.3 131.1s.5 0 0 0h106.3c4.1 0 8.7 0 12.8.5 9.2 1 18-6.2 18.5-15.9v-2.1c-.5-8.7-7.7-15.9-16.4-16.4-5.1-.5-10.3-.5-15.4-.5H228.3c-9.8 0-17.5 7.7-17.5 16.9.6 9.8 8.3 17.5 17.5 17.5"
      className="digits-logo-green_svg__st0"
    />
    <path
      d="m192.4 304.2-52.9-.5c-9.8 0-17.5 7.2-17.5 16.9s7.2 17.5 16.9 17.5l52.9.5c9.8.5 17.5-7.2 18-16.4v-.5c0-9.8-7.7-17.5-17.4-17.5M421.9 114.2c-8.7-4.1-19 0-22.6 8.7s0 19 8.7 22.6h.5c68.3 28.8 116 96 116 175.1 0 46.2-16.4 88.8-44.2 121.7-6.2 7.2-5.1 18 2.1 24.1s18 5.1 24.1-2.1c32.9-39 52.4-89.3 52.4-143.8.1-92.3-56.4-171.9-137-206.3M434.2 485.4c-3.1 0-5.6 1-8.2 2.1-27.2 14.9-58 23.1-91.4 23.1H229.3c-9.8 0-17.5 7.7-17.5 16.9 0 9.8 7.7 17.5 16.9 17.5h105.8c39 0 76-9.8 107.8-27.7 5.6-3.1 9.2-9.2 9.2-15.4.2-9.3-8-17-17.3-16.5M209.8 390.4q0-.75 0 0c0-10.3-8.2-17.5-17.5-17.5H99.4c-9.8 0-17.5 7.7-17.5 16.9 0 9.8 7.7 17.5 16.9 17.5h93.4c9.9.1 17.6-7.6 17.6-16.9"
      className="digits-logo-green_svg__st0"
    />
    <path
      d="M490.2 321.1c0-18-3.1-35.4-8.7-51.9-3.1-9.2-12.8-13.9-22.1-10.3-9.2 3.1-13.9 12.8-10.3 22.1 4.6 12.3 6.7 26.2 6.7 40.1 0 66.8-53.9 120.7-120.7 120.7H137.9c-9.8 0-17.5 7.7-17.5 16.9 0 9.8 7.7 17.5 16.9 17.5h197.2c85.9 0 155.7-69.9 155.7-155.1"
      className="digits-logo-green_svg__st0"
    />
  </svg>
)
