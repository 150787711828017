/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgCommonStock = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={props.width || "100%"}
    height={props.height || "100%"}
    viewBox="0 0 16 16"
    aria-label="SvgCommonStock"
    {...props}
  >
    {props.children}
    <path
      d="M7.5 13.5c-3.309 0-6-2.691-6-6s2.691-6 6-6 6 2.691 6 6-2.691 6-6 6zM7.5 4v.875M7.5 10v1"
      fill="none"
      stroke="#000"
      strokeMiterlimit="10"
    />
    <path
      d="M5.875 8.75v.125c0 .595.405 1.125 1 1.125h1c.743 0 1.25-.507 1.25-1.25S8.618 7.5 7.875 7.5h-.75c-.743 0-1.25-.507-1.25-1.25S6.382 5 7.125 5h.625c.595 0 1.125.53 1.125 1.125v.125"
      fill="none"
      stroke="#000"
      strokeMiterlimit="10"
    />
    <path d="M13 7v1h-2.46c-.07-.4-.16-.75-.16-.75V7zM11.35 3.35 9.96 4.74c-.17-.29-.41-.54-.68-.72l1.37-1.37zM11.35 11.65l-.7.7-1.27-1.27c.28-.18.53-.41.72-.68z" />
  </svg>
)
