/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgMessageCheckSquareGradient = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 66 66"
    aria-label="SvgMessageCheckSquareGradient"
    {...props}
  >
    {props.children}
    <path
      stroke="url(#message-check-square-gradient_svg__a)"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2.885}
      d="m24.92 28.73 5.385 5.384 12.117-12.116M19.535 48.924v6.288c0 1.435 0 2.152.294 2.521.256.32.644.507 1.054.506.471 0 1.031-.448 2.152-1.345l6.423-5.138c1.312-1.05 1.968-1.575 2.699-1.948a8 8 0 0 1 2.05-.72c.804-.164 1.644-.164 3.325-.164h6.775c4.524 0 6.786 0 8.514-.88a8.08 8.08 0 0 0 3.53-3.53c.88-1.729.88-3.99.88-8.515V21.46c0-4.524 0-6.786-.88-8.514a8.08 8.08 0 0 0-3.53-3.53c-1.728-.88-3.99-.88-8.514-.88H21.689c-4.524 0-6.786 0-8.514.88a8.08 8.08 0 0 0-3.53 3.53c-.88 1.728-.88 3.99-.88 8.514v16.694c0 2.504 0 3.757.275 4.784a8.08 8.08 0 0 0 5.712 5.712c1.027.275 2.279.275 4.783.275"
    />
    <defs>
      <linearGradient
        id="message-check-square-gradient_svg__a"
        x1={8.085}
        x2={60.395}
        y1={73.747}
        y2={68.729}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#399CD4" />
        <stop offset={1} stopColor="#5E5BE9" />
      </linearGradient>
    </defs>
  </svg>
)
