/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgFeather = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 24 24"
    aria-label="SvgFeather"
    {...props}
  >
    {props.children}
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M16 8 2 22m16-7H9m-2.4 4h6.737c.245 0 .367 0 .482-.028a1 1 0 0 0 .29-.12c.1-.061.187-.148.36-.32L19.5 13.5c.239-.239.358-.358.455-.464a6 6 0 0 0 0-8.072c-.097-.106-.216-.225-.455-.464s-.358-.358-.464-.455a6 6 0 0 0-8.072 0c-.106.097-.225.216-.464.455L5.469 9.531c-.173.173-.26.26-.322.36a1 1 0 0 0-.12.29C5 10.296 5 10.418 5 10.663V17.4c0 .56 0 .84.109 1.054a1 1 0 0 0 .437.437C5.76 19 6.04 19 6.6 19"
    />
  </svg>
)
