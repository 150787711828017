/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgSignal01Solid = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 24 24"
    aria-label="SvgSignal01Solid"
    {...props}
  >
    {props.children}
    <g fill="#000" fillRule="evenodd" clipRule="evenodd">
      <path d="M15.536 7.05a1 1 0 0 1 1.414 0 7 7 0 0 1 0 9.9 1 1 0 0 1-1.414-1.414 5 5 0 0 0 0-7.072 1 1 0 0 1 0-1.414M8.464 7.05a1 1 0 0 1 0 1.414 5 5 0 0 0 0 7.071A1 1 0 1 1 7.05 16.95a7 7 0 0 1 0-9.9 1 1 0 0 1 1.414 0" />
      <path d="M5.636 4.222a1 1 0 0 1 0 1.414 9 9 0 0 0 0 12.728 1 1 0 1 1-1.414 1.414c-4.296-4.296-4.296-11.26 0-15.556a1 1 0 0 1 1.414 0M18.364 4.222a1 1 0 0 1 1.414 0c4.296 4.296 4.296 11.26 0 15.556a1 1 0 1 1-1.414-1.414 9 9 0 0 0 0-12.728 1 1 0 0 1 0-1.414M9 12a3 3 0 1 1 6 0 3 3 0 0 1-6 0" />
    </g>
  </svg>
)
