/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgEmployeeAdvances = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={props.width || "100%"}
    height={props.height || "100%"}
    viewBox="0 0 16 16"
    aria-label="SvgEmployeeAdvances"
    {...props}
  >
    {props.children}
    <path
      d="M9 7.5H7.013a1 1 0 0 1-.812-.416l-.912-1.268C5.026 5.5 4.736 5.5 4.5 5.5h-2a1 1 0 0 0-1 1v3l1 1v3h2V9"
      fill="none"
      stroke="#000"
      strokeMiterlimit="10"
    />
    <circle cx={3.5} cy={3.5} r={1} fill="none" stroke="#000" strokeMiterlimit="10" />
    <path d="M10 5.5h2M10 7.5h2" fill="none" stroke="#000" strokeMiterlimit="10" />
    <path d="M12 9H6v1h4.513c.36-.475.884-.814 1.487-.939z" />
    <path
      d="M6 2.5h6.5a1 1 0 0 1 1 1V8M13.5 10.4V9M13.512 16v-1.4M11 14.5h3.5a1 1 0 0 0 0-2h-2a1 1 0 0 1 0-2H15"
      fill="none"
      stroke="#000"
      strokeMiterlimit="10"
    />
  </svg>
)
