/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgTelephoneInternet = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    viewBox="0 0 16 16"
    aria-label="SvgTelephoneInternet"
    {...props}
  >
    {props.children}
    <path d="M5.5 2C4.676 2 4 2.676 4 3.5v9c0 .824.676 1.5 1.5 1.5h5c.824 0 1.5-.676 1.5-1.5V12h-1v.5c0 .281-.219.5-.5.5h-5a.494.494 0 0 1-.5-.5v-9c0-.281.219-.5.5-.5h5c.281 0 .5.219.5.5V4h1v-.5c0-.824-.676-1.5-1.5-1.5Zm7.52 2.043-.598.785A4 4 0 0 1 14 8a3.98 3.98 0 0 1-1.582 3.168l.602.789C14.215 11.043 15 9.617 15 8s-.785-3.043-1.98-3.957M11.8 5.641l-.597.78c.48.368.797.93.797 1.579s-.32 1.207-.8 1.57l.6.79C12.52 9.812 13 8.972 13 8s-.48-1.809-1.2-2.36M7 11v1h2v-1Z" />
  </svg>
)
