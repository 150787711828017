/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgChevronLeftSolid = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 24 24"
    aria-label="SvgChevronLeftSolid"
    {...props}
  >
    {props.children}
    <path
      fill="#32497f"
      fillRule="evenodd"
      d="m13.516 17.447-.013-.014-3.908-4.165a5 5 0 0 1-.298-.336 1.3 1.3 0 0 1-.24-.478 1.85 1.85 0 0 1 0-.908c.057-.22.156-.37.24-.478.08-.104.185-.216.298-.336l3.92-4.18c.14-.147.263-.28.369-.37.105-.092.25-.196.42-.18.215.02.415.191.546.467.102.216.128.467.14.658.01.191.01.43.01.695v8.356c0 .265 0 .504-.01.695-.012.191-.038.442-.14.658-.13.276-.33.447-.547.467-.169.016-.314-.088-.42-.18s-.229-.223-.367-.37"
      clipRule="evenodd"
    />
  </svg>
)
