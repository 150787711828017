/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgDental = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={props.width || "100%"}
    height={props.height || "100%"}
    viewBox="0 0 16 16"
    aria-label="SvgDental"
    {...props}
  >
    {props.children}
    <path
      d="m10.5 2.5 3 3-7 9-5-5z"
      fill="none"
      stroke="#000"
      strokeLinejoin="round"
      strokeMiterlimit="10"
    />
    <path
      d="m11 3 2.5-1.5 1 1L13 5z"
      fill="none"
      stroke="#000"
      strokeLinejoin="round"
      strokeMiterlimit="10"
    />
    <path
      d="M7.688 12.688 3.25 8.25"
      fill="none"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
    />
  </svg>
)
