/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgInvoicingProfile = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 48 48"
    aria-label="SvgInvoicingProfile"
    {...props}
  >
    {props.children}
    <path
      stroke="url(#invoicing-profile_svg__a)"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M29.998 41.992v-10.8c0-1.12 0-1.68-.218-2.108a2 2 0 0 0-.874-.874c-.428-.218-.988-.218-2.108-.218h-5.6c-1.12 0-1.68 0-2.108.218a2 2 0 0 0-.874.874c-.218.428-.218.988-.218 2.108v10.8m-12-28a6 6 0 1 0 12 0 6 6 0 0 0 12 0 6 6 0 0 0 12 0m-29.6 28h23.2c2.24 0 3.36 0 4.216-.436a4 4 0 0 0 1.748-1.748c.436-.855.436-1.976.436-4.216v-23.2c0-2.24 0-3.36-.436-4.216a4 4 0 0 0-1.748-1.748c-.856-.436-1.976-.436-4.216-.436h-23.2c-2.24 0-3.36 0-4.216.436a4 4 0 0 0-1.748 1.748c-.436.856-.436 1.976-.436 4.216v23.2c0 2.24 0 3.36.436 4.216a4 4 0 0 0 1.748 1.748c.856.436 1.976.436 4.216.436"
    />
    <defs>
      <linearGradient
        id="invoicing-profile_svg__a"
        x1={5.493}
        x2={44.33}
        y1={53.224}
        y2={49.404}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#399CD4" />
        <stop offset={1} stopColor="#5E5BE9" />
      </linearGradient>
    </defs>
  </svg>
)
