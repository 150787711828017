/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgLegalProfessional = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    viewBox="0 0 16 16"
    aria-label="SvgLegalProfessional"
    {...props}
  >
    {props.children}
    <path d="M7.5 1c-.648 0-1.2.422-1.406 1H2.676L.004 8H0v.004l-.004.012h.008A3.004 3.004 0 0 0 3 11c1.652 0 3-1.348 3-3h-.023L3.766 3h2.328c.152.418.484.754.906.906V13H5v1h5v-1H8V3.906c.422-.152.754-.488.906-.906h2.324L9 8v.004l-.004.012H9A3.01 3.01 0 0 0 12 11c1.652 0 3-1.348 3-3h-.02l-2.652-6H8.906C8.7 1.422 8.148 1 7.5 1m0 1c.281 0 .5.219.5.5s-.219.5-.5.5a.494.494 0 0 1-.5-.5c0-.281.219-.5.5-.5M3 3.734 4.887 8h-3.79Zm9 0L13.887 8h-3.79ZM1.281 9H4.72c-.344.598-.977 1-1.719 1a1.97 1.97 0 0 1-1.719-1m8.996 0h3.446c-.344.598-.98 1-1.723 1a1.98 1.98 0 0 1-1.723-1" />
  </svg>
)
