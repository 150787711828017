/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgCreditCards = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 16 16"
    aria-label="SvgCreditCards"
    width={props.width || "100%"}
    height={props.height || "100%"}
    {...props}
  >
    {props.children}
    <path
      d="M12.5 5.5h1a1 1 0 0 1 1 1v6a1 1 0 0 1-1 1h-9a1 1 0 0 1-1-1v-2"
      fill="none"
      stroke="#000"
      strokeMiterlimit="10"
    />
    <path
      d="M11.5 10.5h-9a1 1 0 0 1-1-1v-6a1 1 0 0 1 1-1h9a1 1 0 0 1 1 1v6a1 1 0 0 1-1 1zM1.5 4.5h11M12.5 7.5h2"
      fill="none"
      stroke="#000"
      strokeMiterlimit="10"
    />
  </svg>
)
