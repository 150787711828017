/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgPatentsLicenses = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={props.width || "100%"}
    height={props.height || "100%"}
    viewBox="0 0 16 16"
    aria-label="SvgPatentsLicenses"
    {...props}
  >
    {props.children}
    <circle cx={7.5} cy={7.5} r={6} fill="none" stroke="#000" strokeMiterlimit="10" />
    <path
      d="M10.091 9.013A3 3 0 0 1 4.5 7.5a3 3 0 0 1 5.596-1.505"
      fill="none"
      stroke="#000"
      strokeLinecap="round"
      strokeMiterlimit="10"
    />
  </svg>
)
