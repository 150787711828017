/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgComissions = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={props.width || "100%"}
    height={props.height || "100%"}
    viewBox="0 0 16 16"
    aria-label="SvgComissions"
    {...props}
  >
    {props.children}
    <path
      d="M.5 3.5v8a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-8a1 1 0 0 0-1-1h-12a1 1 0 0 0-1 1z"
      fill="none"
      stroke="#000"
      strokeMiterlimit="10"
    />
    <path
      d="M7 9.5c-1 0-1 1-2.375 1-1 0-2.125-1-2.125-2 0-.625.5-1 1-1s1 .375 1 1c0 1.004-1.319 2-2.5 2M5 5.5h2M2 5.5h2"
      fill="none"
      stroke="#000"
      strokeLinejoin="round"
      strokeMiterlimit="10"
    />
    <path
      d="M10.512 5.4V4M10.512 11V9.6M8 9.5h3.5a1 1 0 0 0 0-2h-2a1 1 0 0 1 0-2H12"
      fill="none"
      stroke="#000"
      strokeMiterlimit="10"
    />
  </svg>
)
