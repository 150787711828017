/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgSalaries = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={props.width || "100%"}
    height={props.height || "100%"}
    viewBox="0 0 16 16"
    aria-label="SvgSalaries"
    {...props}
  >
    {props.children}
    <circle cx={5} cy={10} r={1.5} fill="none" stroke="#000" strokeMiterlimit="10" />
    <path d="M2.5 14a2.5 2.5 0 1 1 5 0" fill="none" stroke="#000" strokeMiterlimit="10" />
    <path d="M12.5 2h-6C5.673 2 5 2.673 5 3.5V7c.352 0 .686.072 1 .184V3.5a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 .5.5V12H8.437c.183.311.327.643.42 1H14V3.5c0-.827-.673-1.5-1.5-1.5" />
    <path
      d="M7 4.5h2M10 4.5h2M10 6.5h2M10 8.5h2M10 10.5h2M7 6.5h2"
      stroke="#000"
      strokeMiterlimit="10"
    />
    <path d="M7.22 8c.26.289.463.626.596 1H9V8zM8 10c0 .352-.073.686-.185 1H9v-1z" />
  </svg>
)
