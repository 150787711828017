/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgQuestionVerified = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 24 24"
    aria-label="SvgQuestionVerified"
    {...props}
  >
    {props.children}
    <g stroke="#32497f" strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5}>
      <path d="M17.901 4.999c.206.498.601.893 1.1 1.1l1.744.723a2.033 2.033 0 0 1 1.1 2.656l-.722 1.744a2.03 2.03 0 0 0 0 1.556l.722 1.744a2.033 2.033 0 0 1-1.1 2.656L19 17.902A2.03 2.03 0 0 0 17.9 19l-.723 1.745a2.03 2.03 0 0 1-2.656 1.1l-1.744-.722a2.03 2.03 0 0 0-1.555 0l-1.745.723a2.033 2.033 0 0 1-2.654-1.1L6.1 19.001A2.03 2.03 0 0 0 5 17.9l-1.744-.723a2.033 2.033 0 0 1-1.1-2.654l.721-1.744a2.03 2.03 0 0 0 0-1.556l-.722-1.746a2.033 2.033 0 0 1 1.1-2.657L5 6.098c.498-.206.893-.6 1.1-1.098l.723-1.745a2.033 2.033 0 0 1 2.656-1.1l1.744.722a2.03 2.03 0 0 0 1.555-.001l1.746-.72a2.03 2.03 0 0 1 2.655 1.1l.723 1.746z" />
      <path d="M10 9.502a2.249 2.249 0 0 1 4.37.75c0 1.499-2.25 2.248-2.25 2.248m.03 3h.01" />
    </g>
  </svg>
)
