/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgAssistantSend = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 24 22"
    aria-label="SvgAssistantSend"
    {...props}
  >
    {props.children}
    <g filter="url(#assistant-send_svg__a)">
      <path
        fill="#32497F"
        fillOpacity={0.5}
        d="M24 10.726v-.001a.7.7 0 0 0-.125-.393.77.77 0 0 0-.324-.269L12.326 5.066 1.1.07a.8.8 0 0 0-.85.128.74.74 0 0 0-.228.373A.7.7 0 0 0 .048 1l3.494 9.111a2 2 0 0 1 .042 1.313L.58 21.043a.72.72 0 0 0 .194.739q.023.023.05.043a.802.802 0 0 0 .845.097l21.9-10.52c.131-.062.24-.16.317-.279a.7.7 0 0 0 .113-.397"
      />
    </g>
    <path
      fill="#32497F"
      d="M5.259 10.759a2 2 0 0 0-1.906 1.403L.58 21.042a.72.72 0 0 0 .194.74q.022.023.049.043a.802.802 0 0 0 .845.097l21.901-10.52c.13-.062.24-.16.316-.279.124-.196-.074-.397-.306-.397z"
    />
    <defs>
      <filter
        id="assistant-send_svg__a"
        width={44}
        height={42}
        x={-10}
        y={-10}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation={5} />
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_456_142518" />
        <feBlend in="SourceGraphic" in2="effect1_backgroundBlur_456_142518" result="shape" />
      </filter>
    </defs>
  </svg>
)
