/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgFlag01Solid = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 24 24"
    aria-label="SvgFlag01Solid"
    {...props}
  >
    {props.children}
    <path
      fill="#000"
      d="M3 2.997c0-.255.098-.51.293-.704 0 0-.044.038.046-.044l.074-.064q.088-.073.235-.173c.198-.132.479-.29.856-.44C5.259 1.269 6.387 1 8 1c1.693 0 3.097.561 4.316 1.05l.055.022C13.64 2.579 14.72 3 16 3c1.387 0 2.26-.23 2.754-.428.248-.1.405-.192.488-.248a1 1 0 0 0 .073-.053A1 1 0 0 1 21 3v11.999a1 1 0 0 1-.293.708v.001a2 2 0 0 1-.12.107 3 3 0 0 1-.235.173c-.198.132-.479.29-.856.44-.755.303-1.883.572-3.496.572-1.693 0-3.097-.561-4.316-1.05l-.055-.021C10.36 15.42 9.28 15 8 15c-1.387 0-2.26.23-2.754.429a3 3 0 0 0-.246.11V22a1 1 0 1 1-2 0V2.997"
    />
  </svg>
)
