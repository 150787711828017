/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgSettings02Solid = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 24 24"
    aria-label="SvgSettings02Solid"
    {...props}
  >
    {props.children}
    <path
      fill="#000"
      fillRule="evenodd"
      d="m9.742 21.554-.652-1.446a2.7 2.7 0 0 0-1.122-1.228 2.75 2.75 0 0 0-1.64-.344l-1.597.168a2.5 2.5 0 0 1-1.381-.252 2.46 2.46 0 0 1-1.019-.952 2.4 2.4 0 0 1 .142-2.649l.945-1.28a2.645 2.645 0 0 0 0-3.143l-.945-1.28A2.41 2.41 0 0 1 2.33 6.5c.24-.408.593-.739 1.019-.952s.905-.301 1.38-.252l1.602.168a2.75 2.75 0 0 0 1.641-.344A2.7 2.7 0 0 0 9.09 3.892l.652-1.446c.194-.43.51-.796.911-1.053a2.5 2.5 0 0 1 2.694 0c.4.257.717.622.91 1.053l.658 1.446c.231.515.622.945 1.117 1.228a2.75 2.75 0 0 0 1.64.344l1.598-.168c.475-.05.954.038 1.38.252.426.213.78.544 1.019.952a2.4 2.4 0 0 1-.142 2.649l-.945 1.28A2.65 2.65 0 0 0 20.063 12a2.65 2.65 0 0 0 .514 1.578l.945 1.28a2.41 2.41 0 0 1 .142 2.648 2.45 2.45 0 0 1-1.019.952 2.5 2.5 0 0 1-1.38.252l-1.597-.167a2.75 2.75 0 0 0-1.641.343c-.494.281-.884.71-1.117 1.222l-.652 1.446c-.194.43-.51.796-.911 1.053a2.5 2.5 0 0 1-2.693 0 2.44 2.44 0 0 1-.912-1.053M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0"
      clipRule="evenodd"
    />
  </svg>
)
