/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgPayroll = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 25.768 22.179"
    aria-label="SvgPayroll"
    width={props.width || "100%"}
    height={props.height || "100%"}
    {...props}
  >
    {props.children}
    <path d="M8.598 11.668a5.83 5.83 0 0 1-5.834-5.833A5.83 5.83 0 0 1 8.598 0a5.83 5.83 0 0 1 5.834 5.835c-.062 3.254-2.641 5.833-5.834 5.833m0-10.134c-2.396 0-4.361 1.965-4.361 4.359a4.377 4.377 0 0 0 4.361 4.361c2.395 0 4.359-1.965 4.359-4.361.001-2.394-1.964-4.359-4.359-4.359" />
    <path d="M15.906 12.92a9.33 9.33 0 0 0-6.572-2.726A9.33 9.33 0 0 0 0 19.527c0 .432.368.737.736.737.369 0 .676-.306.737-.797 0-4.299 3.5-7.86 7.86-7.86 2.174 0 4.203.865 5.706 2.425zM14.677 9.824a4.4 4.4 0 0 1-1.78-.368.657.657 0 0 1-.37-.922c.123-.366.553-.552.921-.366.369.182.797.245 1.229.245 1.78 0 3.255-1.476 3.255-3.255 0-1.781-1.475-3.256-3.255-3.256-.737 0-1.413.245-2.027.676-.307.246-.798.185-1.044-.122a.765.765 0 0 1 .123-1.043A4.8 4.8 0 0 1 14.615.43a4.665 4.665 0 0 1 4.668 4.666 4.59 4.59 0 0 1-4.606 4.728" />
    <path d="M20.669 11.049a1 1 0 0 0-.097-.119c-1.597-1.656-3.685-2.517-5.896-2.517h-1.78c-.43 0-.738.307-.738.736s.368.736.738.736h1.78c1.691 0 3.278.57 4.524 1.708q.168.153.327.319z" />
    <path d="M20.016 10.669c-3.17 0-5.752 2.584-5.752 5.756s2.582 5.754 5.752 5.754 5.752-2.582 5.752-5.754-2.582-5.756-5.752-5.756m.003 1.402a4.36 4.36 0 0 1 4.354 4.354 4.36 4.36 0 0 1-4.354 4.352 4.36 4.36 0 0 1-4.354-4.352 4.36 4.36 0 0 1 4.354-4.354m-.524.692v1.046h-.522a1.59 1.59 0 0 0-1.569 1.57 1.59 1.59 0 0 0 1.569 1.568h2.093c.27 0 .523.254.523.523s-.254.523-.523.523h-3.662v1.046h2.092v1.047h1.047V19.04h.523a1.59 1.59 0 0 0 1.569-1.569c0-.85-.72-1.569-1.569-1.569h-2.093c-.27 0-.522-.253-.522-.522s.253-.523.522-.523h2.616V13.81h-1.047v-1.046h-1.047z" />
  </svg>
)
