/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgLodging = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={props.width || "100%"}
    height={props.height || "100%"}
    viewBox="0 0 16 16"
    aria-label="SvgLodging"
    {...props}
  >
    {props.children}
    <path d="M10.5 15h-5c-.827 0-1.5-.673-1.5-1.5v-6C4 6.673 4.673 6 5.5 6H8a1 1 0 0 0-.001-2 1 1 0 0 0-.937.668.5.5 0 0 1-.471.332H4.55a.5.5 0 0 1-.495-.572C4.338 2.474 6.034 1 8 1c2.206 0 4 1.794 4 4v8.5c0 .827-.673 1.5-1.5 1.5m-5-8a.5.5 0 0 0-.5.5v6a.5.5 0 0 0 .5.5h5c.275 0 .5-.225.5-.5V5c0-1.654-1.346-3-3-3a3.01 3.01 0 0 0-2.814 2h1.087a2 2 0 0 1 1.726-1A2.001 2.001 0 0 1 8 7z" />
    <path d="m8 9 2 3H6z" />
  </svg>
)
