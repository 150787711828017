/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgIncome = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 25.056 21.565"
    aria-label="SvgIncome"
    width={props.width || "100%"}
    height={props.height || "100%"}
    {...props}
  >
    {props.children}
    <path
      fill="#010101"
      d="M24.306 8.105h-4.488v-1.5h3.738v-.256l-10.841-4.78-10.84 4.78v.257h3.739v1.5H1.125a.75.75 0 0 1-.75-.75V5.859c0-.297.175-.566.447-.687L12.412.063a.76.76 0 0 1 .605 0l11.591 5.109c.271.12.447.39.447.687v1.496a.75.75 0 0 1-.749.75M21.936 9.098h1.5v12.468h-1.5z"
    />
    <path
      fill="#010101"
      d="M0 20.065h25.056v1.5H0zM13.091 13.091h-.75c-2.354 0-2.869-1.801-2.869-2.495 0-1.875 1.877-2.37 2.869-2.37h3.742v1.5h-3.742c-.053.001-1.369.022-1.369.87.01.291.163.995 1.369.995h.75z"
    />
    <path
      fill="#010101"
      d="M14.585 16.456H9.223v-1.5h5.362c.053-.001 1.369-.022 1.369-.87-.01-.291-.163-.995-1.369-.995H12.09v-1.5h2.495c2.354 0 2.869 1.801 2.869 2.495 0 1.875-1.877 2.37-2.869 2.37M12.962 6.355h1.5v2.497h-1.5z"
    />
    <path fill="#010101" d="M12.962 15.829h1.5v2.496h-1.5zM7.356 14.21l-4.113-3.112v6.1z" />
    <path fill="#010101" d="M.252 13.262h3.863v1.772H.252z" />
  </svg>
)
