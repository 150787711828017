/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgOther = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    viewBox="0 0 16 16"
    aria-label="SvgOther"
    {...props}
  >
    {props.children}
    <path d="M10.797.977a1.5 1.5 0 0 0-.293.039h-.008L3.07 3.172c-.64.18-1.07.781-1.07 1.45v7.831c0 .836.66 1.559 1.5 1.559h9c.836 0 1.5-.719 1.5-1.559V5.57c0-.843-.66-1.562-1.5-1.562H3.781l6.965-2.024h.004c.102-.027.129-.007.168.028A.34.34 0 0 1 11 2.25V3h1v-.75c0-.375-.14-.734-.418-.98a1.18 1.18 0 0 0-.785-.293M3.5 5.007h9c.266 0 .5.231.5.563v6.883c0 .328-.234.559-.5.559h-9c-.266 0-.5-.23-.5-.559V5.57c0-.332.234-.562.5-.562M11 8c-.55 0-1 .45-1 1s.45 1 1 1 1-.45 1-1-.45-1-1-1" />
  </svg>
)
