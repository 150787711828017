/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgBellOff01Solid = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 24 24"
    aria-label="SvgBellOff01Solid"
    {...props}
  >
    {props.children}
    <g fill="#000">
      <path
        fillRule="evenodd"
        d="M3.707 2.293a1 1 0 0 0-1.414 1.414l2.856 2.856Q5.001 7.27 5 8c0 2.913-.732 4.844-1.5 6.077l-.011.02c-.358.573-.64 1.027-.83 1.358a6 6 0 0 0-.247.467c-.032.07-.068.157-.096.251a1.2 1.2 0 0 0-.051.45c.01.136.039.37.173.611s.318.39.428.47c.25.187.538.229.634.243h.004c.147.023.315.034.484.04.335.013.799.013 1.376.013h11.222l3.707 3.707a1 1 0 0 0 1.414-1.414L6.997 5.583a1 1 0 0 0-.066-.066z"
        clipRule="evenodd"
      />
      <path d="M8.604 20.338a1 1 0 0 1 1.412-.088c.53.468 1.222.75 1.984.75s1.455-.282 1.984-.75a1 1 0 1 1 1.324 1.5A4.99 4.99 0 0 1 12 23a4.99 4.99 0 0 1-3.308-1.25 1 1 0 0 1-.088-1.412M21.53 17.288a1.5 1.5 0 0 0 .205-.666 1.2 1.2 0 0 0-.05-.449 2 2 0 0 0-.097-.252 6 6 0 0 0-.247-.466c-.19-.33-.472-.785-.83-1.359l-.012-.019C19.732 12.844 19 10.913 19 8A7 7 0 0 0 7.173 2.93z" />
    </g>
  </svg>
)
