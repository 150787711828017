/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgHomeAi = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 22 20"
    aria-label="SvgHomeAi"
    {...props}
  >
    {props.children}
    <path
      fill="#7080A5"
      d="M7.382 4.838H8.6l4.242 9.912h-1.568l-.994-2.45H5.562l-.98 2.45H3.014zm2.408 6.286-1.848-4.55h-.028l-1.876 4.55zm4.341-6.286h1.344v9.912h-1.344z"
    />
  </svg>
)
