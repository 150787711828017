/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgKybcNonProfit = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 18 22"
    aria-label="SvgKybcNonProfit"
    {...props}
  >
    {props.children}
    <g clipPath="url(#kybc-non-profit_svg__a)">
      <path
        fill="#fff"
        d="m8.192 17.26-3.99 4.049c-.197.2-.53.02-.477-.258l.405-2.12a.285.285 0 0 0-.332-.336l-2.077.415c-.274.055-.452-.284-.255-.484l3.931-3.989 3.873-3.616 3.846-3.938c.354-.363.524-.862.462-1.38a1.65 1.65 0 0 0-.333-.811 1.625 1.625 0 0 0-2.458-.148l-1.903 1.95-1.955-1.963a1.624 1.624 0 0 0-2.317.013 1.68 1.68 0 0 0 .01 2.349L6.145 8.51l-2.74 2.78-1.501-1.495-.006-.006c-2.16-2.17-2.179-5.718-.04-7.91C3.752-.064 6.796.337 8.864 2.174 10.944.36 13.93-.07 15.829 1.837a5.6 5.6 0 0 1 1.636 3.947 5.6 5.6 0 0 1-1.594 3.964l-3.861 3.953z"
      />
      <g filter="url(#kybc-non-profit_svg__b)">
        <path
          fill="#fff"
          d="M16.1 18.428c.199.197.027.536-.247.487l-2.154-.387a.285.285 0 0 0-.326.345l.456 2.061c.061.279-.273.468-.474.268l-4.02-4.003-5.93-5.907 2.74-2.78z"
        />
      </g>
      <path fill="#fff" d="M4.204 6.854 7.06 9.71l-2.727 2.727-2.856-2.856z" />
    </g>
    <defs>
      <clipPath id="kybc-non-profit_svg__a">
        <path fill="#fff" d="M17.465.594H.265v20.799h17.2z" />
      </clipPath>
      <filter
        id="kybc-non-profit_svg__b"
        width={18.343}
        height={18.334}
        x={0.625}
        y={5.732}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset />
        <feGaussianBlur stdDeviation={1.39} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_4313_6445" />
        <feBlend in="SourceGraphic" in2="effect1_dropShadow_4313_6445" result="shape" />
      </filter>
    </defs>
  </svg>
)
