/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgTaxesLicenses = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={props.width || "100%"}
    height={props.height || "100%"}
    viewBox="0 0 14 14"
    aria-label="SvgTaxesLicenses"
    {...props}
  >
    {props.children}
    <path
      d="M7.5 1v1.5M7.5 12.5V14M10.5 5v-.5a2 2 0 0 0-2-2H6a2.5 2.5 0 1 0 0 5h3a2.5 2.5 0 1 1 0 5H5.5a2 2 0 0 1-2-2V10"
      fill="none"
      stroke="#000"
      strokeMiterlimit="10"
    />
  </svg>
)
