/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgWorkers = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={props.width || "100%"}
    height={props.height || "100%"}
    viewBox="0 0 16 16"
    aria-label="SvgWorkers"
    {...props}
  >
    {props.children}
    <circle cx={3} cy={9} r={1.5} fill="none" stroke="#000" strokeMiterlimit="10" />
    <path d="M.5 13a2.5 2.5 0 0 1 3.499-2.293" fill="none" stroke="#000" strokeMiterlimit="10" />
    <circle cx={13} cy={9} r={1.5} fill="none" stroke="#000" strokeMiterlimit="10" />
    <path
      d="M11.875 10.767A2.5 2.5 0 0 1 15.5 13"
      fill="none"
      stroke="#000"
      strokeMiterlimit="10"
    />
    <circle cx={8} cy={6} r={2.5} fill="none" stroke="#000" strokeMiterlimit="10" />
    <path d="M3.5 13a4.5 4.5 0 0 1 9 0" fill="none" stroke="#000" strokeMiterlimit="10" />
    <path d="m7 10 1 1 1-1V9H7zM7 12v1h2v-1l-1-1z" />
  </svg>
)
