/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgClipboardAttachmentSolid = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 24 24"
    aria-label="SvgClipboardAttachmentSolid"
    {...props}
  >
    {props.children}
    <path
      fill="#000"
      fillRule="evenodd"
      d="M8.862 1.017C9.07 1 9.316 1 9.568 1h4.864c.252 0 .498 0 .706.017.229.019.499.063.77.201a2 2 0 0 1 .874.874c.138.271.182.541.201.77l.01.146c.394.013.733.045 1.042.128a4 4 0 0 1 2.829 2.829C21 6.476 21 7.067 21 7.863v9.378c0 .805 0 1.47-.044 2.01-.046.563-.145 1.08-.392 1.565a4 4 0 0 1-1.748 1.748c-.485.247-1.002.346-1.564.392-.541.044-1.206.044-2.01.044H8.758c-.805 0-1.47 0-2.01-.044-.563-.046-1.08-.145-1.565-.392a4 4 0 0 1-1.748-1.748c-.247-.485-.346-1.002-.392-1.564C3 18.71 3 18.046 3 17.242v-9.38c0-.795 0-1.386.136-1.897a4 4 0 0 1 2.829-2.829c.31-.083.648-.115 1.043-.128q.003-.076.009-.146c.019-.229.063-.499.201-.77a2 2 0 0 1 .874-.874c.271-.138.541-.182.77-.201m.247 2.529C9 3.76 9 4.04 9 4.6V5h6v-.4c0-.56 0-.84-.109-1.054a1 1 0 0 0-.437-.437C14.24 3 13.96 3 13.4 3h-2.8c-.56 0-.84 0-1.054.109a1 1 0 0 0-.437.437M13 11a.5.5 0 0 1 1 0v4.5a2 2 0 1 1-4 0v-4a1 1 0 1 0-2 0v4a4 4 0 0 0 8 0V11a2.5 2.5 0 0 0-5 0v4.5a1 1 0 1 0 2 0z"
      clipRule="evenodd"
    />
  </svg>
)
