/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgArrowBlockRightSolid = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 24 24"
    aria-label="SvgArrowBlockRightSolid"
    {...props}
  >
    {props.children}
    <path
      fill="#000"
      fillRule="evenodd"
      d="M13.617 4.076a1 1 0 0 1 1.09.217l7 7a1 1 0 0 1 0 1.414l-7 7A1 1 0 0 1 13 19v-3H3.777c-.12 0-.261 0-.387-.01a1.5 1.5 0 0 1-.571-.154 1.5 1.5 0 0 1-.655-.655 1.5 1.5 0 0 1-.154-.571 5 5 0 0 1-.01-.387V9.777c0-.12 0-.262.01-.387.012-.145.042-.353.154-.571a1.5 1.5 0 0 1 .655-.656c.218-.11.426-.141.571-.153.126-.01.268-.01.387-.01H13V5a1 1 0 0 1 .617-.924"
      clipRule="evenodd"
    />
  </svg>
)
