/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgReturnsAllowances = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={props.width || "100%"}
    height={props.height || "100%"}
    viewBox="0 0 16 16"
    aria-label="SvgReturnsAllowances"
    {...props}
  >
    {props.children}
    <path d="M1 5V1.474L4.536 5z" />
    <path
      d="M7.512 5.4V4M7.512 11V9.6M5 9.5h3.5a1 1 0 0 0 0-2h-2a1 1 0 0 1 0-2H9"
      fill="none"
      stroke="#000"
      strokeMiterlimit="10"
    />
    <path d="M7 13.5h.5A6 6 0 1 0 2.626 4" fill="none" stroke="#000" strokeMiterlimit="10" />
    <circle cx={1.5} cy={7.5} r={0.5} />
    <circle cx={2.304} cy={10.5} r={0.5} transform="rotate(-30 2.303 10.5)" />
    <circle cx={4.5} cy={12.696} r={0.5} transform="rotate(-60 4.5 12.696)" />
  </svg>
)
