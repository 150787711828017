/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgSearchCategory = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="-3 -6 31 31"
    aria-label="SvgSearchCategory"
    {...props}
  >
    {props.children}
    <path
      fill="#00A69C"
      fillOpacity={0.3}
      d="M18.987 2.316H8.515a.9.9 0 0 1-.669-.281L6.676.532A.86.86 0 0 0 6.091.25H1.91c-.501 0-.92.47-.92.94v15.12c0 .564.419.94.837.94h17.159c.501 0 .836-.47.836-.94V3.35c.083-.564-.335-1.034-.836-1.034"
    />
    <g filter="url(#search_category_svg__a)">
      <path
        fill="url(#search_category_svg__b)"
        d="M19.257 17.25H1.795c-.51 0-.935-.567-.765-1.134l2.465-10.11c.085-.473.425-.756.765-.756h17.462c.51 0 .935.567.765 1.134l-2.465 10.11c-.085.473-.425.756-.765.756"
      />
    </g>
    <g filter="url(#search_category_svg__c)">
      <path
        fill="#00A69C"
        d="M19.257 17.25H1.795c-.51 0-.935-.567-.765-1.134l2.465-10.11c.085-.473.425-.756.765-.756h17.462c.51 0 .935.567.765 1.134l-2.465 10.11c-.085.473-.425.756-.765.756"
      />
    </g>
    <defs>
      <filter
        id="search_category_svg__a"
        width={25.533}
        height={16}
        x={-1.008}
        y={3.25}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation={1} />
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_18532_229013" />
        <feBlend in="SourceGraphic" in2="effect1_backgroundBlur_18532_229013" result="shape" />
      </filter>
      <filter
        id="search_category_svg__c"
        width={23.533}
        height={14}
        x={-0.008}
        y={4.25}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation={0.5} />
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_18532_229013" />
        <feBlend in="SourceGraphic" in2="effect1_backgroundBlur_18532_229013" result="shape" />
      </filter>
      <linearGradient
        id="search_category_svg__b"
        x1={16.48}
        x2={14.279}
        y1={13.574}
        y2={4.122}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#9AF9FF" stopOpacity={0.69} />
        <stop offset={0.865} stopColor="#D4FEFD" />
      </linearGradient>
    </defs>
  </svg>
)
