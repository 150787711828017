/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgCursor02 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 24 24"
    aria-label="SvgCursor02"
    {...props}
  >
    {props.children}
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M20.506 10.776c.617-.24.925-.36 1.012-.53a.5.5 0 0 0-.006-.466c-.091-.167-.403-.28-1.026-.503L4.596 3.573c-.51-.183-.764-.274-.93-.217a.5.5 0 0 0-.31.31c-.057.166.034.42.217.93l5.704 15.89c.224.623.335.935.503 1.026a.5.5 0 0 0 .466.006c.17-.087.29-.395.53-1.012l2.596-6.678c.047-.12.07-.181.107-.232a.5.5 0 0 1 .117-.117c.05-.036.111-.06.232-.107z"
    />
  </svg>
)
