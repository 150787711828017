/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgBankAccount = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={props.width || "100%"}
    height={props.height || "100%"}
    viewBox="0 0 16 16"
    aria-label="SvgBankAccount"
    {...props}
  >
    {props.children}
    <path
      d="M7.5 13.5c-.5-.875-1.47-1-2-1h-4a1 1 0 0 1-1-1v-8a1 1 0 0 1 1-1h4.172a2 2 0 0 1 1.413.585L7.5 3.5l.415-.415A2 2 0 0 1 9.328 2.5H13.5a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1h-4c-.53 0-1.75.125-2 1M7.5 13.5V3.375"
      fill="none"
      stroke="#000"
      strokeLinecap="round"
      strokeMiterlimit="10"
    />
    <path
      d="M10.49 5.4V4M10.512 11V9.6M9 9.5h2.5a1 1 0 0 0 0-2h-1a1 1 0 0 1 0-2H12"
      fill="none"
      stroke="#000"
      strokeMiterlimit="10"
    />
  </svg>
)
