/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgCheckCircleDuotone = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 17 17"
    aria-label="SvgCheckCircleDuotone"
    {...props}
  >
    {props.children}
    <g
      stroke="#32497F"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.333}
      clipPath="url(#check-circle-duotone_svg__a)"
    >
      <path d="M8.3 15.163a6.667 6.667 0 1 0 0-13.333 6.667 6.667 0 0 0 0 13.333" opacity={0.4} />
      <path d="m5.3 8.496 2 2 4-4" />
    </g>
    <defs>
      <clipPath id="check-circle-duotone_svg__a">
        <path fill="#fff" d="M.3.496h16v16H.3z" />
      </clipPath>
    </defs>
  </svg>
)
