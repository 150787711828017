/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgPromotionalMaterials = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    viewBox="0 0 16 16"
    aria-label="SvgPromotionalMaterials"
    {...props}
  >
    {props.children}
    <path
      d="M5.25 2a.5.5 0 0 0-.186.035l-3.75 1.5A.5.5 0 0 0 1 4v2.5a.5.5 0 0 0 .5.5H3v4.5a.5.5 0 0 0 .5.5H5v1.5a.5.5 0 0 0 .5.5h6a.5.5 0 0 0 .5-.5V9h1.5a.5.5 0 0 0 .5-.5V6a.5.5 0 0 0-.314-.465L12 4.861V4a.5.5 0 0 0-.314-.465l-3.75-1.5A.5.5 0 0 0 7.75 2zm-.17 1.105L6.5 5l1.42-1.893L11 4.34v.775a.5.5 0 0 0 0 .16V6H9.5a.5.5 0 0 0-.5.5V11H4V6.5a.5.5 0 0 0-.5-.5H2V4.338zM12 5.938l1 .4V8h-1.5a.5.5 0 0 0-.5.5V13H6v-1h3.5a.5.5 0 0 0 .5-.5V7h1.5a.5.5 0 0 0 .5-.5z"
      fontFamily="sans-serif"
      fontWeight={400}
      overflow="visible"
    />
  </svg>
)
