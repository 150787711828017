/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgZapFastSolid = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 24 24"
    aria-label="SvgZapFastSolid"
    {...props}
  >
    {props.children}
    <g fill="#000" fillRule="evenodd" clipRule="evenodd">
      <path d="M2.5 17.5a1 1 0 0 1 1-1H9a1 1 0 1 1 0 2H3.5a1 1 0 0 1-1-1M1 12a1 1 0 0 1 1-1h4.5a1 1 0 1 1 0 2H2a1 1 0 0 1-1-1M3 6.5a1 1 0 0 1 1-1h5a1 1 0 0 1 0 2H4a1 1 0 0 1-1-1M17.368 2.07a1 1 0 0 1 .623 1.062L17.142 9.5h3.838c.22 0 .45 0 .639.017.185.017.524.062.826.296a1.5 1.5 0 0 1 .582 1.131c.014.382-.145.683-.24.845a8 8 0 0 1-.357.53l-.02.027-6.596 9.235a1 1 0 0 1-1.805-.713l.849-6.368H11.02c-.22 0-.45 0-.638-.017-.186-.017-.525-.062-.827-.296a1.5 1.5 0 0 1-.582-1.131c-.015-.382.145-.683.24-.845.095-.163.228-.35.357-.53l.02-.027 6.596-9.235a1 1 0 0 1 1.182-.349" />
    </g>
  </svg>
)
