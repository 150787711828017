/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgManagementConsulting = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={props.width || "100%"}
    height={props.height || "100%"}
    viewBox="0 0 16 16"
    aria-label="SvgManagementConsulting"
    {...props}
  >
    {props.children}
    <path
      d="M6.5 1.5h-3a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-10a1 1 0 0 0-1-1H8.625"
      fill="none"
      stroke="#000"
      strokeMiterlimit="10"
    />
    <path
      d="M8.5 2.5h-2V1A.5.5 0 0 1 7 .5h1a.5.5 0 0 1 .5.5z"
      fill="none"
      stroke="#000"
      strokeMiterlimit="10"
    />
    <circle cx={4.5} cy={11.5} r={0.5} />
    <circle cx={9.5} cy={6.5} r={0.5} />
    <path d="M8 5h3L9.5 3.5z" />
    <path
      d="m5.375 10.625 3.375-3.25M8.375 9.375l2.25 2.25M10.625 9.375l-2.25 2.25M4.375 4.375l2.25 2.25M6.625 4.375l-2.25 2.25"
      fill="none"
      stroke="#000"
      strokeMiterlimit="10"
    />
  </svg>
)
