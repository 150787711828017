/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgLightning01 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 24 24"
    aria-label="SvgLightning01"
    {...props}
  >
    {props.children}
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M13 2 4.094 12.688c-.35.418-.524.628-.526.805a.5.5 0 0 0 .185.396c.138.111.41.111.955.111H12l-1 8 8.907-10.688c.349-.418.523-.628.526-.805a.5.5 0 0 0-.186-.396c-.138-.111-.41-.111-.955-.111H12z"
    />
  </svg>
)
