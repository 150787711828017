/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgDistributions = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    aria-label="SvgDistributions"
    width={props.width || "100%"}
    height={props.height || "100%"}
    {...props}
  >
    {props.children}
    <circle cx={7.5} cy={7.5} r={2} strokeLinecap="round" strokeMiterlimit="10" />
    <circle cx={2.5} cy={2.5} r={1} strokeLinecap="round" strokeMiterlimit="10" />
    <circle cx={10.5} cy={2.5} r={1} strokeLinecap="round" strokeMiterlimit="10" />
    <circle cx={13.5} cy={8.5} r={1} strokeLinecap="round" strokeMiterlimit="10" />
    <circle cx={10.5} cy={12.5} r={1} strokeLinecap="round" strokeMiterlimit="10" />
    <circle cx={2.5} cy={11.5} r={1} strokeLinecap="round" strokeMiterlimit="10" />
    <path
      d="m8.542 5.625 1.41-2.25M9.5 7.682l3 .613M8.47 9.246l1.482 2.376M6.002 6.002 3.375 3.375M5.875 8.764l-2.45 1.986"
      strokeLinecap="round"
      strokeMiterlimit="10"
    />
  </svg>
)
