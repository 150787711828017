/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgBenefits = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20.55 22.489"
    aria-label="SvgBenefits"
    width={props.width || "100%"}
    height={props.height || "100%"}
    {...props}
  >
    {props.children}
    <g fill="#010101">
      <path d="M20.387 16.732c-.12.58-.61 1.021-1.03 1.23l-8.9 4.199c-.42.211-.95.33-1.51.33-.69 0-1.22-.14-1.72-.439l-3.8-2.09c-.31-.16-.45-.59-.31-1 .17-.36.63-.511.95-.351l3.8 2.11c.61.34 1.32.37 1.99.09l8.89-4.2c.12-.06.2-.22.28-.39.06-.12.06-.34 0-.47-.09-.181-.16-.33-.29-.391a.42.42 0 0 0-.38 0l-6.15 2.931a.5.5 0 0 1-.189.09c-.45.21-1.03.2-1.48-.021l-3.2-1.6c-.32-.16-.46-.59-.32-1 .13-.4.6-.62.95-.45l3.2 1.6c.13.07.24.07.37 0 .129-.06.209-.22.29-.39.07-.13.07-.34 0-.47-.08-.17-.16-.33-.29-.391l-4.1-2.1c-1.3-.65-2.94-.43-4.02.561l-.52.43v5.89c0 .44-.31.75-.75.75h-1.4a.73.73 0 0 1-.75-.75c0-.439.31-.75.75-.75h.75v-5.35c0-.22 0-.41.18-.58l.12-.13v-.11l.69-.58c.9-.79 2.13-1.25 3.36-1.25.81 0 1.58.24 2.29.51l4.13 2.11c.609.271 1.02.9 1.109 1.74l4.33-2.12.13-.12a1.77 1.77 0 0 1 1.521 0c.44.22.79.64 1.03 1.23a2.27 2.27 0 0 1-.001 1.692M13.166 11.939c-1.98 0-3.494-1.63-3.494-3.494s1.63-3.494 3.494-3.494c1.98 0 3.494 1.63 3.494 3.494s-1.63 3.494-3.494 3.494m0-5.824c-1.281 0-2.33 1.049-2.33 2.33s1.049 2.33 2.33 2.33 2.33-1.049 2.33-2.33-1.049-2.33-2.33-2.33M13.802.636c0-.36-.275-.636-.636-.636s-.636.275-.636.636v2.333c0 .359.275.636.636.636s.636-.276.636-.636zM18.968 2.675a.623.623 0 0 0 0-.898.624.624 0 0 0-.899 0L16.42 3.426a.624.624 0 0 0 0 .899.624.624 0 0 0 .898 0zM8.262 1.776a.623.623 0 0 0-.898 0 .626.626 0 0 0 0 .899l1.649 1.649a.624.624 0 0 0 .899 0 .623.623 0 0 0 0-.898z" />
    </g>
  </svg>
)
