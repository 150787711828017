/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgKybcLlc = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 18 17"
    aria-label="SvgKybcLlc"
    {...props}
  >
    {props.children}
    <g clipPath="url(#kybc-llc_svg__a)">
      <path
        fill="#fff"
        d="M14.57.982H3.233c-.754 0-1.366.637-1.366 1.422V14.84c0 .785.612 1.422 1.366 1.422H14.57c.754 0 1.366-.637 1.366-1.422V2.404c0-.785-.612-1.422-1.366-1.422"
      />
      <path
        fill="#E6E7E8"
        d="m1.905 8.609 7.012 7.629h5.773c.684 0 1.238-.577 1.238-1.288V4.71H2.012z"
      />
      <path
        fill="#fff"
        d="m5.365.955-.611 5.354c-.147 1.285.818 2.415 2.061 2.415 1.146 0 2.076-.967 2.076-2.16V.954z"
      />
      <path
        fill="#fff"
        d="m12.404.955.612 5.354c.146 1.285-.818 2.415-2.062 2.415-1.146 0-2.075-.967-2.075-2.16V.954z"
      />
      <path
        fill="#fff"
        d="m16.156 1.981.94 4.118c.306 1.34-.67 2.624-1.993 2.624-1.038 0-1.912-.807-2.034-1.88L12.396.955h2.512c.594 0 1.11.425 1.248 1.026M1.638 1.981.698 6.1c-.306 1.34.67 2.624 1.993 2.624 1.038 0 1.912-.807 2.035-1.88L5.398.955H2.887c-.595 0-1.111.425-1.249 1.026"
      />
    </g>
    <defs>
      <clipPath id="kybc-llc_svg__a">
        <path fill="#fff" d="M.642.953h16.51v15.308H.643z" />
      </clipPath>
    </defs>
  </svg>
)
