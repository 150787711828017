/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgArrowCircleDownLeftSolid = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 24 24"
    aria-label="SvgArrowCircleDownLeftSolid"
    {...props}
  >
    {props.children}
    <path
      fill="#000"
      fillRule="evenodd"
      d="M19.778 4.222c-4.296-4.296-11.26-4.296-15.556 0s-4.296 11.26 0 15.556 11.26 4.296 15.556 0 4.296-11.26 0-15.556m-4.243 4.242a1 1 0 0 1 0 1.415l-3.95 3.95h3.243a1 1 0 1 1 0 2H9.172a1 1 0 0 1-1-1V9.171a1 1 0 0 1 2 0v3.242l3.95-3.95a1 1 0 0 1 1.413 0"
      clipRule="evenodd"
    />
  </svg>
)
