/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgEventsConferences = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={props.width || "100%"}
    height={props.height || "100%"}
    viewBox="0 0 16 16"
    aria-label="SvgEventsConferences"
    {...props}
  >
    {props.children}
    <path d="M11.5 1v3" fill="none" stroke="#000" strokeMiterlimit="10" />
    <path
      d="M8.5 8.5h3v3h-3z"
      fill="none"
      stroke="#000"
      strokeLinejoin="round"
      strokeMiterlimit="10"
    />
    <path d="M4.5 7C3.121 7 2 5.878 2 4.5S3.121 2 4.5 2c.89 0 1.72.479 2.166 1.25l.865-.5A3.51 3.51 0 0 0 4.5 1C2.57 1 1 2.57 1 4.5S2.57 8 4.5 8c1.758 0 3.204-1.308 3.449-3h-1A2.5 2.5 0 0 1 4.5 7" />
    <path d="M12.5 2H9v1h3.5a.5.5 0 0 1 .5.5V5H8.96l-.021.143A4.4 4.4 0 0 1 8.721 6H13v6.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5V8.238a4.5 4.5 0 0 1-1-.945V12.5c0 .827.673 1.5 1.5 1.5h10c.827 0 1.5-.673 1.5-1.5v-9c0-.827-.673-1.5-1.5-1.5" />
    <path d="M8 4V1.5L5.5 4z" />
    <path d="M4.5 3v1.5H3" fill="none" stroke="#000" strokeMiterlimit="10" />
    <path d="M3 11h1v1H3zM5 11h1v1H5zM3 9h1v1H3zM5 9h1v1H5z" />
  </svg>
)
