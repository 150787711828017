/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgAccumulatedDepreciation = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={props.width || "100%"}
    height={props.height || "100%"}
    viewBox="0 0 16 16"
    aria-label="SvgAccumulatedDepreciation"
    {...props}
  >
    {props.children}
    <path
      d="M1.5 6v8M3.5 7v7M5.5 8v6M7.5 9v5M9.5 10v4M11.5 11v3M13.5 12v2M2.375 1.375 5 4l1-1 3 3 1-1 3 3"
      fill="none"
      stroke="#000"
      strokeMiterlimit="10"
    />
    <path d="M14 9V5.464L10.464 9z" />
  </svg>
)
