/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgOtherContractors = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    viewBox="0 0 16 16"
    aria-label="SvgOtherContractors"
    {...props}
  >
    {props.children}
    <path d="M7 0v1.023A5.53 5.53 0 0 0 2.023 6H1v1h2c.004.11 0 .2 0 .313-.164.062-.352.078-.492.195C2.23 7.73 2 8.078 2 8.5c0 .387.125.77.336 1.125.21.355.535.738 1.07.738.09 0 .188-.027.274-.039.328.977.785 1.88 1.414 2.504C5.804 13.54 6.644 14 7.5 14c.855 0 1.695-.46 2.406-1.172.625-.625 1.086-1.527 1.41-2.504.09.012.188.04.278.04.535 0 .863-.384 1.074-.739.207-.355.332-.738.332-1.125 0-.422-.23-.773-.504-.996-.144-.113-.328-.129-.496-.191V7h2V6h-1.027A5.524 5.524 0 0 0 8 1.023V0Zm0 2.05V4h1V2.05A4.484 4.484 0 0 1 11.95 6h-8.9A4.484 4.484 0 0 1 7 2.05M3.988 7H11v1.094l.469.031c.12.004.289.07.394.156s.137.16.137.219c0 .117-.074.414-.195.617-.121.2-.25.246-.211.246-.063 0-.239-.023-.422-.066l-.465-.117-.137.46c.004-.007-.011.024-.03.094-.259.973-.767 1.813-1.345 2.391C8.621 12.699 7.98 13 7.5 13s-1.121-.3-1.7-.875c-.577-.578-1.081-1.414-1.34-2.387v-.004q-.03-.105-.03-.093l-.133-.461-.469.117a2.5 2.5 0 0 1-.422.066c.04 0-.09-.047-.21-.246C3.077 8.914 3 8.617 3 8.5c0-.059.031-.133.137-.219a.8.8 0 0 1 .394-.156l.524-.035-.059-.52A4 4 0 0 1 3.988 7" />
  </svg>
)
