/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgDigitsLogoNoBackground = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 93.358 87.361"
    aria-label="SvgDigitsLogoNoBackground"
    width={props.width || "100%"}
    height={props.height || "100%"}
    {...props}
  >
    {props.children}
    <defs>
      <path
        id="digits-logo-no-background_svg__a"
        d="M25.372 57.068a3.36 3.36 0 0 0-3.406-3.308H3.911a3.36 3.36 0 1 0 0 6.719h18.056a3.36 3.36 0 0 0 3.406-3.313z"
      />
    </defs>
    <use xlinkHref="#digits-logo-no-background_svg__a" fill="inherit" overflow="visible" />
    <defs>
      <path
        id="digits-logo-no-background_svg__b"
        d="M25.281 43.771a3.36 3.36 0 0 0-3.341-3.378h-.006l-10.33-.073a3.36 3.36 0 0 0-.132 6.72l10.331.073a3.36 3.36 0 0 0 3.478-3.342"
      />
    </defs>
    <use xlinkHref="#digits-logo-no-background_svg__b" fill="inherit" overflow="visible" />
    <defs>
      <path
        id="digits-logo-no-background_svg__c"
        d="M49.679 60.48c-4.214 0-8.544-.177-12.737-.446l-.058-.004a3.36 3.36 0 0 1-2.988-2.436l-.042-.146-.001-.006a3.3 3.3 0 0 1-.115-.909V43.878c-.026-1.86 1.474-3.39 3.352-3.419h.1c1.88.026 3.384 1.556 3.358 3.419v9.632c3.062.156 6.145.251 9.131.251a10.03 10.03 0 0 0 10.08-10.081 10.03 10.03 0 0 0-10.08-10.079H3.313A3.36 3.36 0 0 1 0 30.193a3.36 3.36 0 0 1 3.407-3.312h46.271c9.238 0 16.801 7.562 16.801 16.799 0 9.239-7.562 16.8-16.8 16.8"
      />
    </defs>
    <use
      xlinkHref="#digits-logo-no-background_svg__c"
      fill="inherit"
      fillRule="evenodd"
      clipRule="evenodd"
      overflow="visible"
    />
    <defs>
      <path
        id="digits-logo-no-background_svg__d"
        d="M79.919 43.68c0-3.524-.604-6.923-1.719-10.079a3.362 3.362 0 1 0-6.34 2.245 23.4 23.4 0 0 1 1.338 7.835 23.47 23.47 0 0 1-23.52 23.521h-38.36a3.361 3.361 0 1 0 0 6.719h38.361c16.662-.001 30.24-13.58 30.24-30.241"
      />
    </defs>
    <use xlinkHref="#digits-logo-no-background_svg__d" fill="inherit" overflow="visible" />
    <defs>
      <path
        id="digits-logo-no-background_svg__e"
        d="M72.444 78.974a3.362 3.362 0 0 0-5.014-2.867 36.8 36.8 0 0 1-17.752 4.534H29.136a3.36 3.36 0 1 0 0 6.719h20.543a43.55 43.55 0 0 0 20.98-5.361 3.36 3.36 0 0 0 1.785-3.025"
      />
    </defs>
    <use xlinkHref="#digits-logo-no-background_svg__e" fill="inherit" overflow="visible" />
    <defs>
      <path
        id="digits-logo-no-background_svg__f"
        d="M93.358 43.68c0-18.061-10.988-33.612-26.67-40.247a3.36 3.36 0 1 0-2.618 6.188c13.255 5.608 22.57 18.722 22.57 34.059a36.76 36.76 0 0 1-8.617 23.724 3.361 3.361 0 0 0 5.152 4.318A43.56 43.56 0 0 0 93.358 43.68"
      />
    </defs>
    <use xlinkHref="#digits-logo-no-background_svg__f" fill="inherit" overflow="visible" />
    <defs>
      <path
        id="digits-logo-no-background_svg__g"
        d="M69.603 22.339a3.36 3.36 0 0 0-1.326-2.5 30.16 30.16 0 0 0-18.598-6.398H11.367a3.36 3.36 0 1 0 0 6.719h38.312a23.37 23.37 0 0 1 14.465 4.975 3.361 3.361 0 0 0 5.459-2.796"
      />
    </defs>
    <use xlinkHref="#digits-logo-no-background_svg__g" fill="inherit" overflow="visible" />
    <defs>
      <path
        id="digits-logo-no-background_svg__h"
        d="M55.81 3.295A3.36 3.36 0 0 0 52.632.099 44 44 0 0 0 49.679 0H29.051a3.36 3.36 0 1 0-.095 6.719h20.722q1.265.001 2.5.085a3.36 3.36 0 0 0 3.632-3.509"
      />
    </defs>
    <use xlinkHref="#digits-logo-no-background_svg__h" fill="inherit" overflow="visible" />
  </svg>
)
