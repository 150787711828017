/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgEmployerBenefitLiabilities = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    viewBox="0 0 16 16"
    aria-label="SvgEmployerBenefitLiabilities"
    {...props}
  >
    {props.children}
    <path d="M7.492.992A.5.5 0 0 0 7 1.5v.52c-3.422.21-6 2.554-6 5.48V8h1.063l.148-.25a1.484 1.484 0 0 1 2.578 0l.149.25h1.128l.145-.25a1.484 1.484 0 0 1 2.582 0l.145.25h1.128l.145-.25a1.484 1.484 0 0 1 2.582 0l.145.25H14v-.5c0-2.926-2.578-5.27-6-5.48V1.5a.5.5 0 0 0-.145-.363.5.5 0 0 0-.363-.145m-.047 2.012a.5.5 0 0 0 .11 0c2.664.02 4.578 1.453 5.175 3.332A2.5 2.5 0 0 0 11.5 6c-.793 0-1.453.422-1.906 1H9.41c-.457-.578-1.113-1-1.91-1-.793 0-1.453.422-1.906 1h-.188C4.953 6.422 4.293 6 3.5 6c-.45 0-.867.129-1.227.336.594-1.879 2.508-3.313 5.172-3.332M7 8v4c0 .559-.441 1-1 1s-1-.441-1-1H4c0 1.098.902 2 2 2s2-.902 2-2V8Z" />
  </svg>
)
