/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgStar02Solid = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 24 24"
    aria-label="SvgStar02Solid"
    {...props}
  >
    {props.children}
    <path
      fill="#000"
      d="M12.611 1.634a1.5 1.5 0 0 0-1.222 0c-.327.145-.521.395-.626.544-.102.146-.208.33-.307.502L8.542 6.01l-3.84-.007c-.2 0-.412-.001-.59.015-.181.016-.494.06-.784.27a1.5 1.5 0 0 0-.611 1.058c-.037.356.082.649.158.814.075.162.182.345.282.517L5.084 12l-1.927 3.322c-.1.173-.207.356-.282.518a1.54 1.54 0 0 0-.158.814 1.5 1.5 0 0 0 .611 1.058c.29.21.603.254.784.27.178.016.39.016.59.015l3.84-.008 1.914 3.33c.099.173.205.357.307.503.105.15.3.398.626.544a1.5 1.5 0 0 0 1.222 0c.327-.146.521-.395.626-.544.102-.146.208-.33.307-.502l1.914-3.33 3.841.007c.2 0 .411.001.589-.015.181-.016.494-.06.784-.27a1.5 1.5 0 0 0 .611-1.058 1.54 1.54 0 0 0-.158-.814 7 7 0 0 0-.282-.518L18.916 12l1.927-3.323c.1-.172.207-.355.282-.517.076-.165.195-.458.158-.814a1.5 1.5 0 0 0-.611-1.059 1.54 1.54 0 0 0-.784-.27 7 7 0 0 0-.589-.014l-3.84.007-1.915-3.33a7 7 0 0 0-.307-.502c-.105-.15-.299-.399-.626-.544"
    />
  </svg>
)
