/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgRevenue = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 20.143 20.454"
    aria-label="SvgRevenue"
    width={props.width || "100%"}
    height={props.height || "100%"}
    {...props}
  >
    {props.children}
    <path d="M9.3 20.454c-.384 0-.773-.112-1.126-.323a.7.7 0 0 1-.118-.088L.564 13.259c-.303-.223-.527-.638-.56-1.094a1.54 1.54 0 0 1 .431-1.206l8.888-9.696c.483-.482 1.088-.79 1.823-.938a1 1 0 0 1 .106-.014l5.467-.304c.056-.004.134-.007.209-.007 1.578 0 2.911 1.32 2.911 2.884l.304 5.425c.001.876-.249 1.504-.741 1.908l-8.767 9.674a1.86 1.86 0 0 1-1.335.563m-.3-1.58c.248.127.477.055.548-.018l8.783-9.694a1 1 0 0 1 .106-.097q.204-.155.205-.715l-.303-5.425c0-.779-.659-1.425-1.411-1.425l-.104.004-5.432.303c-.422.091-.745.253-.986.494l-8.887 9.695c.019.014.035.136.052.151z" />
    <path d="M15.094 6.463a1.71 1.71 0 0 1-1.709-1.711c0-.942.767-1.709 1.709-1.709s1.71.767 1.71 1.709-.768 1.711-1.71 1.711m0-2.119a.409.409 0 1 0 0 .817.409.409 0 0 0 0-.817" />
  </svg>
)
