/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgXVerified = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 24 24"
    aria-label="SvgXVerified"
    {...props}
  >
    {props.children}
    <g stroke="#32497f" strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5}>
      <path d="M17.9 4.995c.207.498.602.893 1.1 1.1l1.745.723a2.033 2.033 0 0 1 1.1 2.656l-.722 1.744a2.03 2.03 0 0 0 0 1.556l.721 1.744a2.03 2.03 0 0 1-1.1 2.657L19 17.897a2.03 2.03 0 0 0-1.1 1.099l-.723 1.745a2.033 2.033 0 0 1-2.656 1.1l-1.744-.722a2.03 2.03 0 0 0-1.555.001l-1.745.722a2.03 2.03 0 0 1-2.654-1.1l-.724-1.745a2.03 2.03 0 0 0-1.098-1.1l-1.746-.724a2.033 2.033 0 0 1-1.1-2.654l.722-1.744a2.03 2.03 0 0 0 0-1.556l-.722-1.746a2.033 2.033 0 0 1 1.1-2.657L5 6.094c.498-.206.893-.6 1.1-1.098l.723-1.745a2.033 2.033 0 0 1 2.656-1.1l1.744.722a2.03 2.03 0 0 0 1.555-.001l1.746-.72a2.033 2.033 0 0 1 2.655 1.1l.723 1.746zM14.4 9.602l-4.8 4.8m0-4.8 4.8 4.8" />
    </g>
  </svg>
)
