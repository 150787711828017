/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgLayersTwo01 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 24 24"
    aria-label="SvgLayersTwo01"
    {...props}
  >
    {props.children}
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="m2 14.5 9.642 4.821c.131.066.197.098.266.111q.091.018.184 0c.069-.013.135-.045.266-.11L22 14.5m-20-5 9.642-4.821c.131-.066.197-.099.266-.111a.5.5 0 0 1 .184 0c.069.012.135.045.266.11L22 9.5l-9.642 4.821c-.131.066-.197.098-.266.111a.5.5 0 0 1-.184 0c-.069-.013-.135-.045-.266-.11z"
    />
  </svg>
)
