/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgAdditionalCapital = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={props.width || "100%"}
    height={props.height || "100%"}
    viewBox="0 0 16 16"
    aria-label="SvgAdditionalCapital"
    {...props}
  >
    {props.children}
    <path
      d="M12.5 4.5h-6a1 1 0 0 0-1 1v9h8v-9a1 1 0 0 0-1-1z"
      fill="none"
      stroke="#000"
      strokeMiterlimit="10"
    />
    <path
      d="M7 6.5h2M10 6.5h2M10 8.5h2M10 10.5h2M10 12.5h2M7 8.5h2M7 10.5h2M7 12.5h2"
      stroke="#000"
      strokeMiterlimit="10"
    />
    <path
      d="M5.5 9.5h-4v-7a1 1 0 0 1 1-1h3a1 1 0 0 1 1 1v1.875"
      fill="none"
      stroke="#000"
      strokeMiterlimit="10"
    />
    <path d="M3 3.5h2M3 5.5h2M3 7.5h2" stroke="#000" strokeMiterlimit="10" />
  </svg>
)
