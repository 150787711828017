/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgVendor = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 45 45"
    aria-label="SvgVendor"
    width={props.width || "100%"}
    height={props.height || "100%"}
    {...props}
  >
    {props.children}
    <g filter="url(#vendor_svg__a)">
      <path
        fill="url(#vendor_svg__b)"
        fillOpacity={0.7}
        d="M36.408 36.426c0 .383-.27.715-.644.798-10.853 2.406-15.193 2.41-26.523-.004a.82.82 0 0 1-.651-.8v-9.284c0-.496.434-.88.926-.82 2.251.278 8.207.946 13.392.946 5.096 0 10.441-.645 12.558-.93a.83.83 0 0 1 .942.819zM12.583 24.642h-1.597v-8.425h1.597zm21.326 0h-1.597v-8.425h1.597z"
        shapeRendering="crispEdges"
      />
    </g>
    <g filter="url(#vendor_svg__c)">
      <path
        fill="url(#vendor_svg__d)"
        fillOpacity={0.8}
        d="M11.001 15.558c-3.168 0-3.638-.944-3.638-1.506v-.103l1.348-4.904a.82.82 0 0 1 .789-.601h11.444v.002H35.23c.369 0 .691.247.789.602l1.347 4.904v.103c0 .563-.47 1.505-3.638 1.505-1.424 0-2.303-.19-2.84-.452-.536.261-1.415.452-2.838.452-1.427 0-2.306-.19-2.843-.453-.535.262-1.415.454-2.842.454-.847 0-2.055-.077-2.843-.453-.536.261-1.416.452-2.842.452v-.002c-1.421 0-2.3-.19-2.836-.451-.536.262-1.416.454-2.843.454z"
        shapeRendering="crispEdges"
      />
    </g>
    <g filter="url(#vendor_svg__e)">
      <path
        fill="#fff"
        d="M10.987 15.805c-3.156 0-3.624-.976-3.624-1.558v-.107L8.71 9.053a.82.82 0 0 1 .79-.61h6.046l-.941 5.891c-.046.59-.617 1.473-3.617 1.473z"
      />
    </g>
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M22.09 23.171a3.273 3.273 0 1 0 0-6.545 3.273 3.273 0 0 0 0 6.545M16.363 28.9c0-2.548 1.274-4.756 3.133-5.837.706.58 1.61.928 2.595.928.984 0 1.888-.348 2.594-.928 1.86 1.081 3.133 3.29 3.133 5.837H16.363"
      clipRule="evenodd"
    />
    <g filter="url(#vendor_svg__f)">
      <path
        fill="#fff"
        d="M34.014 15.805c3.156 0 3.623-.945 3.623-1.527v-.107L36.17 9.037a.82.82 0 0 0-.786-.593h-5.929l.942 5.89c.045.59.617 1.473 3.617 1.473z"
      />
    </g>
    <g filter="url(#vendor_svg__g)">
      <path
        fill="#fff"
        d="M22.586 15.805c-1.405 0-3.77-.203-3.77-1.558l.007-.087.628-5.716h6.27l.635 5.803c0 .582-.486 1.558-3.77 1.558"
      />
    </g>
    <g filter="url(#vendor_svg__h)">
      <path
        fill="url(#vendor_svg__i)"
        fillOpacity={0.7}
        d="M37.636 28.48a.82.82 0 0 1-.637.796c-11.07 2.521-17.58 2.488-28.991-.004a.82.82 0 0 1-.645-.798v-1.867c0-.51.46-.9.963-.815 2.484.417 9.283 1.47 14.583 1.47 5.224 0 11.395-1.023 13.749-1.452a.83.83 0 0 1 .978.815z"
        shapeRendering="crispEdges"
      />
    </g>
    <defs>
      <filter
        id="vendor_svg__a"
        width={34.362}
        height={30.176}
        x={5.317}
        y={13.762}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation={1.227} />
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_5043_88287" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy={1.636} />
        <feGaussianBlur stdDeviation={1.636} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
        <feBlend in2="effect1_backgroundBlur_5043_88287" result="effect2_dropShadow_5043_88287" />
        <feBlend in="SourceGraphic" in2="effect2_dropShadow_5043_88287" result="shape" />
      </filter>
      <filter
        id="vendor_svg__c"
        width={31.64}
        height={8.753}
        x={6.545}
        y={7.626}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation={0.409} />
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_5043_88287" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy={0.818} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.6 0" />
        <feBlend in2="effect1_backgroundBlur_5043_88287" result="effect2_dropShadow_5043_88287" />
        <feBlend in="SourceGraphic" in2="effect2_dropShadow_5043_88287" result="shape" />
      </filter>
      <filter
        id="vendor_svg__e"
        width={9.82}
        height={9}
        x={6.545}
        y={8.444}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy={0.818} />
        <feGaussianBlur stdDeviation={0.409} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_5043_88287" />
        <feBlend in="SourceGraphic" in2="effect1_dropShadow_5043_88287" result="shape" />
      </filter>
      <filter
        id="vendor_svg__f"
        width={9.816}
        height={9}
        x={28.639}
        y={8.444}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy={0.818} />
        <feGaussianBlur stdDeviation={0.409} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_5043_88287" />
        <feBlend in="SourceGraphic" in2="effect1_dropShadow_5043_88287" result="shape" />
      </filter>
      <filter
        id="vendor_svg__g"
        width={9.175}
        height={8.997}
        x={17.998}
        y={8.444}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy={0.818} />
        <feGaussianBlur stdDeviation={0.409} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_5043_88287" />
        <feBlend in="SourceGraphic" in2="effect1_dropShadow_5043_88287" result="shape" />
      </filter>
      <filter
        id="vendor_svg__h"
        width={36.819}
        height={11.919}
        x={4.091}
        y={22.508}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation={1.227} />
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_5043_88287" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset />
        <feGaussianBlur stdDeviation={1.636} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend in2="effect1_backgroundBlur_5043_88287" result="effect2_dropShadow_5043_88287" />
        <feBlend in="SourceGraphic" in2="effect2_dropShadow_5043_88287" result="shape" />
      </filter>
      <linearGradient
        id="vendor_svg__b"
        x1={22.5}
        x2={22.5}
        y1={15.808}
        y2={38.631}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#fff" />
        <stop offset={0.422} stopColor="#fff" />
        <stop offset={0.687} stopColor="#fff" stopOpacity={0.21} />
        <stop offset={1} stopColor="#fff" />
      </linearGradient>
      <linearGradient
        id="vendor_svg__d"
        x1={35.591}
        x2={35.591}
        y1={8.444}
        y2={15.398}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#fff" />
        <stop offset={1} stopColor="#fff" />
      </linearGradient>
      <linearGradient
        id="vendor_svg__i"
        x1={22.5}
        x2={22.5}
        y1={26.427}
        y2={32.651}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#fff" />
        <stop offset={1} stopColor="#fff" stopOpacity={0} />
      </linearGradient>
    </defs>
  </svg>
)
