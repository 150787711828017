/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgFurniture = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={props.width || "100%"}
    height={props.height || "100%"}
    viewBox="0 0 16 16"
    aria-label="SvgFurniture"
    {...props}
  >
    {props.children}
    <path d="M3.5 14v-1.375M12.5 14v-1.375" fill="none" stroke="#000" strokeMiterlimit="10" />
    <path
      d="M2.5 9.125V4.5a2 2 0 0 1 2-2h7a2 2 0 0 1 2 2v4.625"
      fill="none"
      stroke="#000"
      strokeLinejoin="round"
      strokeMiterlimit="10"
    />
    <path
      d="M3.5 12.5a1 1 0 0 1-.707-.293l-2-2a.999.999 0 1 1 1.414-1.414L3.914 10.5h8.172l1.707-1.707a.999.999 0 1 1 1.414 1.414l-2 2a1 1 0 0 1-.707.293z"
      fill="none"
      stroke="#000"
      strokeMiterlimit="10"
    />
  </svg>
)
