/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgCursor04Solid = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 24 24"
    aria-label="SvgCursor04Solid"
    {...props}
  >
    {props.children}
    <path
      fill="#000"
      d="M4.657 2.445a7 7 0 0 0-.592-.156c-.18-.036-.505-.085-.85.044a1.5 1.5 0 0 0-.882.882c-.13.345-.08.67-.045.85s.098.392.157.592L6.669 19.02c.055.186.113.385.178.547.066.168.195.45.47.666a1.5 1.5 0 0 0 1.17.298 1.54 1.54 0 0 0 .733-.359c.134-.111.28-.258.417-.395L12 17.414l3.744 3.744c.084.084.184.185.28.266.111.095.28.22.512.296.302.098.626.098.927 0 .233-.076.402-.201.513-.296.096-.081.196-.182.28-.266l2.902-2.901c.084-.085.185-.185.266-.281.094-.111.22-.28.295-.512a1.5 1.5 0 0 0 0-.927 1.5 1.5 0 0 0-.295-.513 5 5 0 0 0-.266-.28L17.414 12l2.363-2.363c.137-.136.283-.283.395-.417a1.54 1.54 0 0 0 .359-.732 1.5 1.5 0 0 0-.299-1.17 1.54 1.54 0 0 0-.665-.471 7 7 0 0 0-.547-.177z"
    />
  </svg>
)
