/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgProfessionalServices = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={props.width || "100%"}
    height={props.height || "100%"}
    viewBox="0 0 16 16"
    aria-label="SvgProfessionalServices"
    {...props}
  >
    {props.children}
    <path
      d="m8.169 1.709 1.003.724 1.225.167c.501.056.891.446.947.947l.223 1.226.724 1.003c.279.39.279.947 0 1.393l-.724 1.003-.167 1.225a1.07 1.07 0 0 1-.947.947l-1.281.223-1.003.724c-.39.279-.947.279-1.393 0l-.947-.724-1.226-.167a1.07 1.07 0 0 1-.947-.947l-.223-1.282-.724-1.002c-.279-.39-.279-.947 0-1.393l.724-.947.167-1.226A1.32 1.32 0 0 1 4.603 2.6l1.226-.167 1.003-.724a1.17 1.17 0 0 1 1.337 0z"
      fill="none"
      stroke="#000"
      strokeMiterlimit="10"
    />
    <path
      d="M4.562 10.438 2.5 12.5h2v2l3-3M10.438 10.438 12.5 12.5h-2v2l-3-3"
      fill="none"
      stroke="#000"
      strokeLinejoin="round"
      strokeMiterlimit="10"
    />
  </svg>
)
