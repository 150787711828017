/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgBrush01 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 24 24"
    aria-label="SvgBrush01"
    {...props}
  >
    {props.children}
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M9 11.222 12.778 15m-4.803 5.975C6.608 22.342 4 22 2 22c1.025-2-.342-4.608 1.025-5.975a3.5 3.5 0 0 1 4.95 4.95m3.947-5.05 9.137-9.868a2.204 2.204 0 0 0-3.116-3.116l-9.868 9.137c-.51.472-.765.709-.913.96a2 2 0 0 0-.038 1.968c.139.257.385.503.876.994s.737.737.994.876a2 2 0 0 0 1.968-.038c.251-.149.487-.404.96-.913"
    />
  </svg>
)
